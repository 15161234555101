.settings-page{
    display: flex;
    width: 100%;
    justify-content: center;
    height: 100vh;
}

#settings-window{
    display: flex;
    max-width: 1400px;
    flex-direction: column;
    border-radius: 20px;
    padding: 20px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0) rgba(0, 0, 0, 0);
}

#div-with-search{
    display: flex;
}

#task-flame-in-days{
    display: flex;
    color: var(--text-color);
    font-weight: 600;
    max-width: 100px;
    border-radius: 10px;
    font-family: var(--base-font-family);
    background-color: var(--base-btn-color);
    align-items: center;
    padding: 10px;
    text-align: left;
    padding-right: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

#change-username-on-fio{
    color: var(--text-color);
    align-self: center;
    font-weight: 600;
    font-size: 16px;
    padding-left: 5px;
    font-family: var(--base-font-family);
}

#task-flame-in-days-right{
    display: flex;
    font-weight: 600;
    color: var(--text-color);
    justify-content: center;
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    font-family: var(--base-font-family);
    background-color: var(--base-btn-color);;
    align-items: center;
    border: none;
    padding-left: 5px;
    padding-right: 10px;
    max-width: 70px;
}

#div-with-all-element-role{
    display: flex;
    width: 1000px;
    overflow-y: auto;
    flex-direction: column;
    margin-left: auto;
    gap: 15px;
}

#task-flame-in-days-txt{
    max-width: 40px;
    display: flex;
    color: var(--text-color);
    font-size: 40px;
    font-weight: 600;
    font-family: var(--base-font-family);
    background-color: var(--base-btn-color);
    align-items: center;
    border: none;
    text-align: center;
    outline: none;
}

#search-group{
    display: flex;
    width: 100%;
    padding: 15px;
    border: var(--base-border-input);
    border-radius: 10px;
    font-weight: 400;
    background: none;
    transition: background-color 0.3s ease, transform 0.1s ease;
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: left;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 18px;
    outline: none;
}

#hint-for-group{
    display: flex;
    color: var(--base-hint-text-color);
    font-family: var(--base-font-family);
    font-size: 16px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
}

#div-with-names-and-checkbox{
    max-height: 500px;
    overflow-y: auto;
}

#performerlabel-create-task-label{
    font-size: 20px;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-weight: 600;
    display: flex;
    max-width: 550px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    word-break: break-all;
    justify-content: center;
}

#template-settings-button{
    display: flex;
    border-radius: 10px;			
    background-color: var(--base-btn-color);
    color: var(--text-color);
    font-size: 18px;
    padding: 10px;
    font-family: var(--base-font-family);
    font-weight: 600;
    border: none;
    justify-content: center;
    cursor: pointer;
}

#post-select{
    display: flex;
    width: 100%;
    min-width: max-content;
    padding: 14px;
    font-weight: 600;
    color: var(--base-text-color);
    border: none;
    margin-top: 10px;
    background-color: var(--base-select-filter-color);
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 30px;
}

#create-user-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

#div-right-button{
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 200px;
    margin-right: 20px;
}

#not-found-role-label{
    display: flex;
    color: var(--hint-no-permissions-or-null-data);
    font-family: var(--base-font-family);
    align-self: center;
    font-weight: 600;
    font-size: 50px;
}

#div-with-role-and-desc{
    display: flex;
    justify-content: center;
    padding: 15px;
    width: 900px;
    background: var(--base-task-color);
    border-radius: 15px;
}

#name-role-and-count-permission{
    display: flex;
    width: fit-content;
    margin-right: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#open-settings-role{
    background: var(--base-btn-color);
    padding: 15px;
    word-break: break-all;
    border: 0px;
    width: 135px;
    border-radius: 10px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--text-color);
    font-family: var(--base-font-family);
    font-size: 16px;
    font-weight: 700;
    overflow: hidden;
    white-space: wrap;
    display: flex;
    height: fit-content;
    transition: background-color 0.3s ease, transform 0.1s ease, box-shadow 0.3s ease;
}

#open-settings-role:hover{
    background: var(--base-btn-hover-color);
    color: var(--text-color);
    box-shadow: var(--base-btn-hover-color) 0.5px 2px 20px;
}

#div-for-right-btn-in-settings{
    display: flex;
    margin-bottom: 15px;
    margin-right: 15px;
    flex-direction: column;
    gap: 10px;
}

#count-permission{
    color: var(--base-text-color);
    min-width: 130px;
    font-family: var(--base-font-family);
    max-width: 200px;
    font-size: 12px;
    justify-content: center;
    margin-top: 5px;
}

#desc-role{
    display: flex;
    min-height: 60px;
    min-width: 730px;
    background: none;
    outline: none;
    border: var(--base-border-input);
    border-radius: 8px;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 18px;
    font-weight: 500;
    padding: 10px;
    resize: none;
}

#div-change-username-or-fio{
    display: flex;
    width: 170px;
    height: fit-content;
    background: var(--base-btn-color);
    border-radius: 10px;
    padding: 10px;
}

#custom-checkbox-settings{
    display: flex;
    justify-content: center;
    align-items: center;
}

#div-with-permission-role{
    display: flex;
    max-height: 510px;
    flex-wrap: wrap;
    max-width: 250px;
    overflow-y: auto;
    gap: 10px;
    scrollbar-width: none;
    padding: 10px;
    border-radius: 10px;
    scrollbar-color: rgba(255, 255, 255, 0) rgba(0, 0, 0, 0);
}

#up-role-modal-div{
    display: flex;
    justify-content: left;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media (min-width: 560px) {
    #div-with-permission-role{
        min-width: 510px;
    }
}

@media (width < 560px) {
    .div-select-all-and-add-role {
        flex-direction: column;
        align-items: center;
    }
}

@media (min-width: 820px) {
    #div-with-permission-role{
        min-width: 770px;
    }
}

#create-name-role{
    display: flex;
    border: var(--base-border-input);
    border-radius: 30px;
    background: none;
    display: flex;
    align-items: center;
    text-align: left;
    text-wrap: wrap;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 0px !important;
}

#nameRole{
    display: flex;
    padding: 10px;
    border: var(--base-border-input);
    border-radius: 20px;
    background: var(--base-bg-color-input);
    overflow: hidden;
    align-items: center;
    text-align: left;
    resize: none;
    outline: none;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 18px;
    max-height: 135px;
}

#checkbox-with-perm{
    display: flex;
    align-items: center;
}

#selected-all-permission{
    display: flex;
    font-family: var(--base-font-family);
    color: var(--base-text-color);
    font-size: 20px;
    font-weight: 600;
}

#namePerm{
    display: flex;
    font-family: var(--base-font-family);
    font-weight: 500;
    color: var(--base-text-color);
    font-size: 14px;
    margin-right: 15px;
}

#name-per{
    width: 200px;
    display: flex;
    align-items: center;
}

#div-with-performer-and-labels{
    display: flex;
    flex-direction: column;
    max-height: 150px;
    width: 210px;
    overflow-y: scroll;
    margin-left: 10px;
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0) rgba(0, 0, 0, 0);
}

#performerlabel-change{
    font-size: 20px;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-weight: 600;
    display: flex;
    justify-content: center;
    line-height: 29px;
}

#copy-link{
    display: flex;
    width: 25px;
    height: 25px;
    margin-left: auto;
    cursor: pointer;
}

#div-with-invite-link{
    display: flex;
    border-radius: 20px;
    background: none;
    padding: 10px;
    align-items: center;
    border: var(--base-border-input);
}

#invite-link{
    display: flex;
    flex-wrap: wrap;
    word-break: break-all;
    border: none;
    color: var(--base-text-color);
    padding: 5px;
    font-weight: 600;
    font-family: var(--base-font-family);
    background-color: transparent;
    font-size: 16px;
}

#div-create-link{
    display: flex;
    max-width: 470px;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
}

#div-with-time-active-link{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

#date-end-active-link{
    display: flex;
    margin-top: 5px;
    margin-bottom: 10px;
    font-family: var(--base-font-family);
    font-size: 14px;
    color: var(--base-hint-text-color);
    align-self: center;
}

#label-mini-title{
    display: flex;
    font-weight: 600;
    color: var(--base-text-color);
    font-size: 16px;
    font-family: var(--base-font-family);
}

#login-user-create-link{
    display: flex;
    margin-bottom: 10px;
    border-radius: 20px;
    border: var(--base-border-input);
    font-family: var(--base-font-family);
    color: var(--base-text-color);
    background: none;
    font-size: 16px;
    padding-left: 10px;
    height: 40px;
}

#send-link-for-user{
    display: flex;
    padding: 5px;
    height: 50px;
    border-radius: 20px;
    font-weight: 600;
    font-size: 16px;
    color: var(--text-color);
    padding: 15px;
    border: none;
    cursor: pointer;
    align-items: center;
    font-family: var(--base-font-family);
    background: var(--btn-invite-user-organization-color);
    transition: background-color 0.3s ease, transform 0.1s ease, box-shadow 0.3s ease;
}

#hint-for-send-link-user{
    display: flex;
    margin-left: 10px;
    font-size: 14px;
    font-family: var(--base-font-family);
    color: var(--base-hint-text-color);
    align-self: center;
}

#select-with-days-active-link{
    border-radius: 10px;
    border: var(--base-border-input);
    padding-left: 8px;
    font-size: 16px;
    color: var(--base-text-color);
    font-weight: 600;
    background: none;
    height: 40px;
    width: fit-content;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

#div-detail-template-task{
    display: flex;
    flex-direction: column;
    max-height: 960px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-width: 800px;
}

#div-template-task{
    max-width: 580px;
}

#open-role-btn{
    display: flex;
    padding: 5px;
    width: 180px;
    height: 40px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 18px;
    margin-left: auto;
    color: var(--text-color);
    padding: 15px;
    border: none;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    font-family: var(--base-font-family);
    background: var(--btn-invite-user-organization-color);
    transition: background-color 0.3s ease, transform 0.1s ease, box-shadow 0.3s ease;
}

.div-select-all-and-add-role{
    display: flex;
    padding: 10px;
    align-items: center;
}

#image-users{
    border-radius: 5px;
    border: none;
    min-width: 25px;
    height: 25px;
    margin-right: 10px;
}

#nameuser{
    display: flex;
    color: var(--base-text-color);
    font-size: 18px;
    margin-right: 15px;
    margin-top: 1px;
}