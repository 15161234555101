.App {
    container-name: sidebar-create-task;
    container-type: inline-size;
}

.modal-add-data-component {
    display: flex;
    flex-direction: column;
    animation: slideInRight 0.5s forwards;
    background: transparent;
    background-color: var(--task-modal-element-color);
    box-shadow: var(--task-right-element-shadow);
    backdrop-filter: blur(10px);
    overflow: hidden;
    border-radius: 20px;
    padding: 20px;
    max-height: 60vh;
    width: fit-content;
    outline: none;
}

.overlay-add-data-component {
    display: flex;
    justify-content: flex-end;
    background: none;
    position: fixed;
    top: 20%;
    right: 0;
    z-index: 10;
    width: fit-content;
}

.div-data-component-list-img-return{
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.div-data-component-list-img{
    width: 25px;
    height: 25px;
    margin-right: 5px;
    margin-left: auto;
}

.div-data-component-list-title{
    color: var(--title-data-task-color);
    font-size: 24px;
    font-family: var(--base-font-family);
}

.data-component-add{
    display: flex;
    border-radius: 30px;
    cursor: pointer;
    align-items: center;
}

.div-data-component-list{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    border-radius: 10px 10px 20px 20px;
    width: fit-content;
    background: none;
    gap: 10px;
}

.create-task-page::-webkit-scrollbar,
.div-data-component-list::-webkit-scrollbar,
.App::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
}

.create-task-page::-webkit-scrollbar-track,
.div-data-component-list::-webkit-scrollbar-track,
.App::-webkit-scrollbar-track {
    background: rgba(34, 35, 43, 0.6);
}

.create-task-page::-webkit-scrollbar-thumb,
.div-data-component-list::-webkit-scrollbar-thumb,
.App::-webkit-scrollbar-thumb {
    background: #7F8094;
}

.label-component-task {
    min-width: 170px;
    color: var(--title-data-task-color);
    font-size: 19px;
    font-family: var(--base-font-family);
}

.component-task-user{
    display: flex;
    background: var(--base-btn-color);
    border-radius: 30px;
    margin-right: 10px;
    max-height: 35px;
    padding: 5px;
}

#component-task-image-performer{
    border-radius: 30px;
    max-width: 30px;
    max-height: 30px;
}

#component-task-open-profile-performer{
    background: none;
    border: none;
    color: var(--base-text-color);
    font-size: 19px;
    font-family: var(--base-font-family);
    cursor: pointer;
}

.btn-open-change-data{
    display: flex;
    width: 55px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    max-height: 43px;
    background: var(--base-btn-color);
    border: none;
    color: var(--text-color);
    font-size: 19px;
    font-family: var(--base-font-family);
    font-weight: 600;
    padding: 15px 10px 15px 10px;
    outline: none;
    cursor: pointer;
    border-radius: 30px;
    align-items: center;
    transition: all 0.3s ease;
}

#img-header-task{
    width: 25px;
    height: 25px;
}

.btn-open-change-data span {
    display: none;
    opacity: 0;
    transform: translateX(-20px);
    transition: all 0.3s ease;
}

.btn-open-change-data:hover span {
    display: flex;
    opacity: 1;
    transform: translateX(0);
}

.btn-open-change-data:hover{
    display: flex;
    min-width: fit-content;
    max-height: 43px;
    background: var(--base-btn-color);
    border: none;
    color: var(--text-color);
    font-size: 19px;
    font-family: var(--base-font-family);
    font-weight: 600;
    padding: 10px 15px 10px 15px;
    outline: none;
    cursor: pointer;
    border-radius: 20px;
    align-items: center;
}
.btn-open-change-data:hover #component-task-image-author,
.btn-open-change-data:hover #img-header-task{
    margin-right: 10px;
}

#btn-add-new-data-component-task{
    background: var(--base-btn-color);
    margin-left: auto;
    border: none;
    color: var(--base-text-color);
    font-size: 19px;
    font-family: var(--base-font-family);
    font-weight: 600;
    padding: 10px 15px 10px 15px;
    outline: none;
    cursor: pointer;
    border-radius: 20px;
}

#confirmtask-create {
    background: var(--create-btn-color);
    border: none;
    color: var(--text-color);
    font-size: 19px;
    font-family: var(--base-font-family);
    font-weight: 600;
    padding: 10px 15px 10px 15px;
    outline: none;
    cursor: pointer;
    border-radius: 20px;
    margin-left: auto;
    margin-top: 15px;
}

.create-task-page {
    display: flex;
    padding: 20px 20px 0px 0px;
    align-self: flex-start;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 95vh;
    background: none;
    border-radius: 10px;
}

.custom-select-time{
    width: 55px;
    height: 38px;
    overflow: hidden;
    border-radius: 8px;
    border: var(--base-border-input);
    resize: none;
    padding-left: 5px;
    font-size: 20px;
    outline: none;
    color: var(--base-text-color);
    background: var(--base-btn-color);
    font-weight: bold;
    font-family: var(--base-font-family);
    cursor: pointer;
}

.custom-select-time::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
}
.custom-select-time::-webkit-scrollbar-track {
    background: rgba(34, 35, 43, 0.6);
}
.custom-select-time::-webkit-scrollbar-thumb {
    background: #7F8094;
}

.div-calendar{
    width: 255px;
}

.custom-label-time{
    font-weight: bold;
    color: var(--base-text-color);
    font-size: 30px;
    cursor: pointer;
}

.left-column-task-create {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;
}

#img-component-task{
    width: 25px;
    height: 25px;
    margin-right: 15px;
    margin-left: 10px;
}

.div-data-component-task{
    display: flex;
    flex: 1;
    min-width: 0px;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 10px;
}

.label-name-component-task{
    font-weight: bold;
    font-family: var(--base-font-family);
}

.right-column-task-create{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    gap: 5px;
    container-name: right-column-create-task-container;
    container-type: inline-size;
}

#button-titletask{
    display: flex;    
}

@container right-column-create-task-container (width < 640px) {
    .div-priority{
        display: none !important;
        opacity: 0 !important;
        width: 0px !important;
    }
    .custom-priority-select {
        position: relative;
        width: 100% !important;
        max-width: 320px !important;
        font-family: var(--base-font-family);
        margin: 20px 0 !important;
    }
    
    .selected-priority-option {
        display: flex !important;
        opacity: 1 !important;
        justify-content: center !important;
        font-family: var(--base-font-family);
        background: var(--priority-select-color);
        border-radius: 30px !important;
        cursor: pointer !important;
        transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) !important;
        color: var(--base-text-color);
        font-size: 16px !important;
        box-shadow: 0 4px 6px rgba(0,0,0,0.1) !important;
        height: 30px !important;
        width: fit-content !important;
        min-width: 20px !important;
        padding: 5px 15px 5px 15px !important;
        align-items: center !important;
        text-align: center !important;
    }
    
    .priority-options {
        opacity: 1 !important;
        font-family: var(--base-font-family);
        position: absolute !important;
        width: 50px !important;
        max-height: 240px !important;
        color: var(--base-text-color);
        background: var(--priority-select-color);
        border-radius: 30px !important;
        margin-top: 10px !important;
        box-shadow: 0 8px 16px rgba(0,0,0,0.2) !important;
        z-index: 1000 !important;
        transform-origin: top !important;
        animation: fadeIn 0.2s ease-out !important;
        overflow-y: scroll !important;
        scrollbar-width: none !important;
        bottom: 100% !important;
        
        &::-webkit-scrollbar {
            display: none !important;
            width: 0 !important; 
            height: 0 !important;
        }
        
        &::-webkit-scrollbar-track {
            display: none !important;
            width: 0 !important; 
            height: 0 !important;
        }
        
        &::-webkit-scrollbar-thumb {
            background: none !important;
        }
    }
    
    .priority-option {
        font-family: var(--base-font-family);
        padding: 14px 20px !important;
        cursor: pointer !important;
        transition: all 0.2s ease !important;
        position: relative !important;
        
        &:last-child {
            border-bottom: none !important;
        }
        
        &::before {
            content: '' !important;
            position: absolute !important;
            left: 0 !important;
            top: 0 !important;
            bottom: 0 !important;
            width: 4px !important;
            background: var(--priority-select-selected-color);
            opacity: 0 !important;
            transition: opacity 0.2s !important;
        }
        
        &:hover::before {
            opacity: 1 !important;
        }
    }
    
    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: scaleY(0.9);
        }
        to {
            opacity: 1;
            transform: scaleY(1);
        }
    }

    .priority-option[aria-selected="true"] {
        font-weight: 500 !important;
        
        &::before {
            opacity: 1 !important;
        }
    }

    .custom-priority-select[aria-expanded="true"] .selected-priority-option svg {
        transform: rotate(180deg) !important;
    }
}

#title-and-button{
    display: flex;
    width: 100%;
}

@container sidebar-create-task (width > 1600px) {
    .create-task-page {
        display: grid;
        grid-template-columns: 1.9fr 1.1fr;
        grid-auto-rows: min-content;
        box-shadow: none;
        background-color: transparent;
        border-radius: 0px;
        padding: 20px 20px 0px 0px;
        border-radius: 10px;
    }

    .left-column-task-create {
        display: flex;
        grid-column: 1;
    }

    .create-task-page > :last-child{
        grid-column: 2;
    }

    .right-column-task-create-row-2 {
        overflow: hidden;
        align-self: start;
        max-width: 100%;
        margin-left: 40px;
        grid-column: 2;
        grid-row: 2 / span 3;
        margin-top: 395px;
        background-color: var(--task-modal-element-color);
        box-shadow: var(--task-right-element-shadow);
        backdrop-filter: blur(10px);
        border-radius: 10px;
        padding: 10px 20px 20px 20px;
    }

    .right-column-task-create {
        grid-column: 2;
        grid-row: 1 / span 2;
        overflow: hidden;
        max-width: 100%;
        margin-left: 40px;
        display: flex;
        background-color: var(--task-modal-element-color);
        box-shadow: var(--task-right-element-shadow);
        backdrop-filter: blur(10px);
        border-radius: 10px;
        padding: 20px;
        align-self: start;
    }
}

.data-component-task-div{
    display: flex;
    align-items: center;
    height: 43px;
    overflow: hidden;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.data-component-task-div * {
    cursor: pointer !important;
}

.data-component-task-div:hover{
    background-color: var(--task-data-element-hover-color);
    border-radius: 5px;
}

@media (min-width: 1600px) {
    @supports not (container-type: inline-size) {
        .create-task-page {
            display: grid;
            grid-template-columns: 2fr 1fr;
            grid-auto-rows: min-content;
            box-shadow: none;
            background-color: transparent;
            border-radius: 0px;
            padding: 20px 20px 0px 0px;
        }

        .left-column-task-create {
            display: flex;
            grid-column: 1;
        }

        .create-task-page > :last-child{
            grid-column: 2;
        }

        .right-column-task-create {
            grid-column: 2;
            grid-row: 1 / span 2;
            max-width: 800px;
            margin-left: 40px;
            display: flex;
            background-color: var(--task-modal-element-color);
            box-shadow: var(--task-right-element-shadow);
            backdrop-filter: blur(10px);
            border-radius: 10px;
            padding: 20px;
            align-self: start;
        }
    }
}

.div-priority {
    display: flex;
    opacity: 1;
    margin-left: 5px;
    transition: all 0.3s ease;
}

.mobile-priority-select{
    display: none;
    opacity: 0;
    width: 0px;
    transition: all 0.3s ease;
}
.selected-priority-option {
    opacity: 0;
    display: none;
}
@media (max-width: 790px) {
    .div-priority{
        display: none;
        opacity: 0;
        width: 0px;
    }

    .custom-priority-select {
        position: relative;
        width: 100%;
        max-width: 320px;
        font-family: var(--base-font-family);
        margin: 20px 0;
    }
    
    .selected-priority-option {
        display: flex;
        opacity: 1;
        justify-content: center;
        font-family: var(--base-font-family);
        background: var(--priority-select-color);
        border-radius: 30px;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        color: var(--base-text-color);
        font-size: 16px;
        box-shadow: 0 4px 6px rgba(0,0,0,0.1);
        height: 30px;
        width: fit-content;
        min-width: 20px;
        align-items: center;
        text-align: center;
    }
    
    .priority-options {
        opacity: 1;
        font-family: var(--base-font-family);
        position: absolute;
        width: 50px;
        max-height: 240px;
        overflow-y: auto;
        color: var(--base-text-color);
        background: var(--priority-select-color);
        border-radius: 30px;
        margin-top: 10px;
        box-shadow: 0 8px 16px rgba(0,0,0,0.2);
        z-index: 1000;
        transform-origin: top;
        animation: fadeIn 0.2s ease-out;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        
        &::-webkit-scrollbar {
            display: none;
            width: 0; 
            height: 0;
        }
        
        &::-webkit-scrollbar-track {
            display: none;
            width: 0; 
            height: 0;
        }
        
        &::-webkit-scrollbar-thumb {
            background: none;
        }
    }
    
    .priority-option {
        font-family: var(--base-font-family);
        padding: 14px 20px;
        cursor: pointer;
        transition: all 0.2s ease;
        position: relative;
        
        &:last-child {
            border-bottom: none;
        }
        
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 4px;
            background: var(--priority-select-selected-color);
            opacity: 0;
            transition: opacity 0.2s;
        }
        
        &:hover::before {
            opacity: 1;
        }
    }
    
    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: scaleY(0.9);
        }
        to {
            opacity: 1;
            transform: scaleY(1);
        }
    }

    .priority-option[aria-selected="true"] {
        font-weight: 500;
        
        &::before {
            opacity: 1;
        }
    }
    #title-and-button{
        display: grid !important;
        width: 100% !important;
    }
    #title-task{
        grid-column: 1 !important;
        grid-row: 1 !important;
    }
    #title-task{
        grid-column: 1 !important;
        grid-row: 3 !important;
    }
    #button-titletask{
        grid-column: 1;
        grid-row: 1;
        align-items: center;
        justify-content: center;
    }

    .custom-priority-select[aria-expanded="true"] .selected-priority-option svg {
        transform: rotate(180deg);
    }
}

.priority-options {
    opacity: 0;
}
@media (max-width: 499px) {
    .create-task-page {
        padding-top: 80px;
        height: 80vh;
        padding-left: 20px;
    }
    #title-and-button{
        display: grid !important;
        width: 100% !important;
    }
    #title-task{
        grid-column: 1 !important;
        grid-row: 1 !important;
    }
    #title-task{
        grid-column: 1 !important;
        grid-row: 3 !important;
    }
    #button-titletask{
        grid-column: 1;
        grid-row: 1;
        align-items: center;
        justify-content: center;
    }
}

#performerlabel-create-task {
    font-size: 20px;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-weight: 600;
    display: flex;
    justify-content: center;
    line-height: 29px;
}

#search-input-subtasks-div {
    margin-top: 10px;
    width: 100%;
    border: var(--base-border-input);
    padding: 10px;
    border-radius: 10px;
    background: var(--base-bg-color-input);
    display: flex;
    align-items: center;
    text-align: left;
    text-wrap: wrap;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 16px;
    font-weight: 500;
    outline: none;
}

#btn-with-labels-name-task {
    background-color: var(--btn-data-element-color);
    border: none;
    color: var(--base-text-color);
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    font-family: var(--base-font-family);
    font-size: 16px;
    width: 100%;
    cursor: pointer;
}

#name-labels {
    word-break: break-all;
    border: none;
    border-radius: 5px;
    font-family: var(--base-font-family);
    font-size: 16px;
    font-weight: 600;
    width: fit-content;
    height: fit-content;
    padding: 5px;
    cursor: pointer;
}

#title-task {
    display: flex;
    color: var(--base-text-color);
    resize: none;
    border: var(--base-border-input);
    border-radius: 20px;
    background: none;
    overflow: hidden;
    font-size: 19px;
    font-weight: bold;
    padding-top: 10px;
    padding-left: 10px;
    outline: none;
    width: 100%;
    margin-right: 10px;
    max-height: 135px;
    font-family: var(--base-font-family);
}

.label-data-component-task {
    color: var(--base-text-color);
    font-size: 19px;
    font-family: var(--base-font-family);
    text-wrap: nowrap;
    overflow: hidden;
}

#div-with-place-file {
    border-radius: 15px;
    border: var(--file-place-border);
    margin-top: 15px;
    width: 100%;
    min-height: 110px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

#add-files-icon{
    margin-left: auto;
    width: 20px;
    height: 20px;
    border-radius: 30px;
    background-color: var(--add-files-icon-color);
    padding: 15px;
}

#div-with-place-file:hover {
    border-radius: 15px;
    border: var(--file-place-border-hover);
    background-color: var(--file-place-bg-hover-color);
}

#label-place-file{
    font-size: 18px;
    cursor: pointer;
    font-family: var(--base-font-family);
    font-weight: bold;
    color: var(--base-text-color);
    margin-left: auto;
}

#place-file-here {
    display: none;
}

.rating-item {
    display: flex;
    width: 40px;
    height: 40px;
    font-family: var(--base-font-family);
    font-weight: 600;
    margin-right: 3px;
    box-shadow: none;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#file-info-create {
    background-color: var(--base-btn-color);
    margin-right: 15px;
    padding: 5px;
    padding-left: 7px;
    padding-right: 7px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    color: var(--base-text-color);
    font-size: 16px;
    font-family: var(--base-font-family);
    text-wrap: nowrap;
    margin-top: 10px;
}

#place-file-here {
    margin-top: 10px;
    cursor: pointer;
    border: none;
    padding: 10px;
    width: fit-content;
    padding-top: 5px;
    border-radius: 8px;
    box-shadow: none;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.1s ease, box-shadow 0.3s ease;
}

.hint-new-label {
    color: var(--base-text-color);
    font-size: 16px;
    margin-left: 2px;
    display: flex;
    font-family: var(--base-font-family);
    margin-top: 10px;
    font-weight: 500;
    margin-bottom: 5px;
}

#new-name-label {
    display: flex;
    height: 10px;
    background: none;
    border: none;
    padding: 15px;
    font-size: 16px;
    font-weight: 600;
    color: var(--base-text-color);
    outline: none;
    border: var(--base-border-input);
    border-radius: 10px;
}

#new-name-label::placeholder {
    font-size: 16px;
    font-weight: 600;
    color: var(--base-placeholder-input);
}

#delete-file {
    cursor: pointer;
    margin-top: 5px;
    margin-left: 7px;
    width: 25px;
    height: 25px;
    color: var(--base-text-color);
    background: none;
    border: none;
}

#btn-del-file {
    width: 15px;
    height: 15px;
}

#div-ckeditor {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ck.ck-editor__editable > .ck-placeholder::before {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
}