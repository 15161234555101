#divforfilter{
    display: flex;
    margin-top: 20px;
    height: 40px;
    width: 1600px;
    justify-content: center;
    align-items: center;
}

#divforlisttasks{
    align-items: center;
    flex-direction: column;
    height: 92vh;
    background: none;
    margin-top: 10px;
    overflow-y: scroll;
    scrollbar-width: thin;
    width: 100%;
    scrollbar-color: rgba(255, 255, 255, 0) rgba(0, 0, 0, 0);
    background: none;
    z-index: 0;
    transition: all 0.5s ease;
}

#tasksdivlist:first-child{
    margin-top: 5px;
}

#tasksdivlist{
    display: flex;
    border-radius: 10px;
    flex-direction: row;
    padding: 7px;
    width: 1560px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    background: var(--task-list-color);
    transition: all 1s ease-in-out;
}

#div-bottom-button-default-filter{
    display: flex;
    margin-top: auto;
}

.data-default-filter{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    max-width: 400px;
}

.label-component-filter{
    min-width: 200px;
    color: var(--base-text-color);
    font-size: 20px;
    font-weight: 600;
    font-family: var(--base-font-family);
}

.open-more-filter{
    display: flex;
    border-radius: 30px;
    color: var(--text-color);
    font-family: var(--base-font-family);
    font-size: 20px;
    background: var(--base-btn-color);
    border: none;
    padding: 10px 15px 10px 15px;
    cursor: pointer;
    font-weight: 500;
    align-items: center;
    margin-left: 5px;
}

.Custom-select-filter{
    border: none;
    position: absolute;
    background-color: var(--base-select-filter-color);
    box-shadow: inset 0px 0px 15px 5px var(--base-select-filter-color);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    z-index: 2;
    color: var(--base-text-color);
}

#default-sorted-icon{
    cursor: pointer;
    margin-left: 10px;
    margin-top: 5px;
    width: 30px;
    height: 30px;
}

#null-tasks{
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-color: rgba(255, 255, 255, 0) rgba(0, 0, 0, 0);
    scrollbar-width: thin;
    text-align: center;
    justify-content: center;
    font-family: var(--base-font-family);
    font-weight: 600;
    font-size: 50px;
    color: var(--hint-no-permissions-or-null-data);
    text-wrap: wrap;
    padding: 10px;
    background: none;
}

#tasks{
    padding: 10px;
    text-align: left;
    width: 400px;
    min-height: 40px;
    font-size: 16px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    height: fit-content;
    margin-left: 10px;
    text-wrap: wrap;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-weight: 700;
    background: none;
    word-wrap: break-word;
}

.maindivfortaskslist{
    position: relative;
    display: flex;
    background: none;
    flex-direction: column;
    min-width: 100%;
    align-items: center;
}

#divforalllabel{
    display: flex;
    justify-content: center;
    padding: 5px;
    width: 140px;
    margin-left: 80px;
    flex-direction: column;
    color: var(--base-text-color);
}

#datetimetaskslist{
    width: 210px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: var(--base-font-family);
    font-weight: 600;
    color: var(--base-text-color);
    justify-content: center;
}

#divforallperformer{
    display: flex;
    width: 150px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#labelwithperformerusername{
    display: flex;
    overflow: hidden;
    max-width: 140px;
    font-size: 15px;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-weight: 600;
    
    align-items: center;
}

#divforperformerusername{
    display: flex;
    width: 180px;
    height: fit-content;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#grouptasklist{
    width: 160px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    font-family: var(--base-font-family);
    font-weight: 500;
    justify-content: center;
    color: var(--base-text-color);
}

#prioritytasklist{
    width: 80px;
    margin-left: 70px;
    font-family: var(--base-font-family);
    display: flex;
    display: grid;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 30px;
    margin-right: 10px;
}

#deadlinetasklist{
    width: 160px;
    display: flex;
    margin-left: auto;
    margin-right: -40px;
    flex-direction: row;
    font-family: var(--base-font-family);
    color: var(--base-text-color);
    align-items: center;
    justify-content: center;
}

#search-filter-custom-select{
    display: flex;
    outline: none;
    border: none;
    height: 40px;
    margin-bottom: none;
    background: none;
    border-radius: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-family: var(--base-font-family);
    font-size: 18px;
    color: var(--base-text-color);
    padding-left: 10px;
    margin-bottom: 5px;
}

.custom-div-data-filter{
    overflow-y: auto;
    max-height: 40vh;
}

.custom-div-data-filter::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
}
.custom-div-data-filter::-webkit-scrollbar-track {
    background: rgba(34, 35, 43, 0.6);
}
.custom-div-data-filter::-webkit-scrollbar-thumb {
    background: #7F8094;
}

#search-filter-custom-select::placeholder{
    color: #7F8094;
}

.filter-div-select{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    line-height: 1;
    padding-left: 5px;
    padding-right: 5px;
    color: var(--base-text-color);
    text-wrap: wrap;
    word-wrap: break-word;
}

.custom-filter-label{
    font-family: var(--base-font-family);
    font-size: 16px;
    color: var(--base-text-color);
    max-width: 170px;
}

#custom-check-box{
    display: flex;
    min-width: 10px;
    min-height: 10px;
    cursor: pointer;
    margin-left: auto;
}

#button-open-custom-filter-priority{
    display: flex;
    margin-left: 10px;
    max-height: 20px;
    min-width: 35px;
    max-width: 35px;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    font-family: var(--base-font-family);
    background: var(--task-list-color);
    color: var(--base-text-color);
    align-items: center;
    font-size: 20px;
}

#search{
    display: flex;
    min-width: 350px;
    outline: none;
    height: 10px;
    padding: 15px;
    border: none;
    background: var(--bg-color-input);
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: left;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 18px;
    font-weight: 500;
    line-height: 10px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    outline: none;
}

#open-calendar{
    margin-left: 15px;
    display: flex;
    border-radius: 8px;
    width: 170px;
    color: var(--base-text-color);
    justify-content: center;
    background: var(--task-list-color);
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 10px;
    height: 23px;
}

#open-calendar-btn{
    display: flex;
    padding-left: 10px;
    justify-content: center;
    background: none;
    border: none;
    color: var(--base-text-color);
    letter-spacing: 0.2px;
    font-weight: 700;
    cursor: pointer;
    font-size: 16px;
}

#button-reset-filter{
    display: flex;
    width: 30px;
    height: 40px;
    border: none;
    color: var(--base-text-color);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background: var(--bg-color-input);
    cursor: pointer;
    font-size: 16px;
    align-items: center;
}

#button-open-custom-filter{
    display: flex;
    margin-left: 10px;
    min-width: 140px;
    max-width: 140px;
    color: var(--base-text-color);
    border: none;
    padding: 10px;
    cursor: pointer;
    font-weight: 700;
    letter-spacing: 0.2px;
    border-radius: 8px;
    font-family: var(--base-font-family);
    background: var(--task-list-color);
    align-items: center;
}

#toggle{
    display: flex;
    font-size: 12px;
    margin-left: auto;
}

#search-and-reset{
    display: flex;
    height: 40px;
    outline: none;
    border-radius: 5px;
    border: none;
    margin-right: 50px;
}

#go-to-board{
    display: flex;
    margin-left: auto;
    padding: 5px;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: var(--base-text-color);
    font-weight: 600;
    font-family: var(--base-font-family);
    height: fit-content;
    background-color: var(--base-btn-color);
    font-size: 13px;
    cursor: pointer;
}

#task-number{
    display: flex;
    white-space: pre-line;
    font-size: 12px;
    line-height: 10px;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-weight: 600;
}