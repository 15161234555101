.sidebar{
    display: flex;
    position: fixed;
    width: 80px;
    left: 20px;
    top: 20px;
    bottom: 20px;
    flex-direction: column;
    background-color: var(--base-sidebar-color);
    box-shadow: inset 0px 0px 100px 5px #ffffff13;
    backdrop-filter: blur(10px);
    z-index: 10;
    border-radius: 15px;
    overflow: hidden;
}

.Number-task{
    display: flex;
    color: var(--base-text-color);
    margin-top: 20px;
    font-size: 20px;
    font-family: var(--base-font-family);
    font-weight: 600;
    align-self: center;
    position: relative;
    cursor: pointer;
}

.Number-task div{
    opacity: 0;
    transition: all 0.3s ease;
    width: 0px;
}

.sidebar:hover .Number-task div{
    opacity: 1;
    width: fit-content;
    margin-right: 10px;
}

#sidebar-tooltip{
    background: var(--base-tooltip-color);
    color: var(--text-color);
    font-family: var(--base-font-family);
    border-radius: 10px;
    font-weight: 800;
    z-index: 10;
}

.sidebar span {
    opacity: 0;
    transform: translateX(-20px);
    transition: all 0.3s ease;
}

.sidebar:hover span {
    opacity: 1;
    transform: translateX(0);
}

.sidebar:hover{
    display: flex;
    position: fixed;
    left: 20px;
    top: 20px;
    bottom: 20px;
    flex-direction: column;
    background-color: var(--sidebar-hover-color);
    box-shadow: inset 0px 0px 100px 5px #ffffff13;
    backdrop-filter: blur(10px);
    z-index: 10;
    border-radius: 15px;
    width: fit-content;
}

.sidebar:hover #img-header-task{
    margin-right: 10px;
}

.btn-aside{
    display: flex;
    background: none;
    height: 40px;
    border: none;
    color: var(--base-text-color);
    font-size: 19px;
    font-family: var(--base-font-family);
    font-weight: 600;
    cursor: pointer;
    align-items: center;
    width: 250px;
}

#img-aside-icon{
    width: 30px;
    height: 30px;
}

#redirectProfile{
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: left;
    color: var(--base-text-color);
    background: none;
    font-family: var(--base-font-family);
    font-weight: 700;
    border: none;
    margin-top: 30px;
    margin-right: auto;
    margin-left: 10px;
    height: 55px;
    width: 320px;
    word-break: break-all;
    white-space: normal;
}

.aside-line{
    height: 2px;
    background: var(--line-main-color);
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
}

#username-image-asidebar{
    width: 50px;
    height: 50px;
    border-radius: 30px;
}

#username-asidebar{
    color: var(--base-text-color);
    font-size: 20px;
    font-family: var(--base-font-family);
}

#post-asidebar{
    color: var(--post-sidebar-color);
    font-size: 14px;
    font-family: var(--base-font-family);
}

#btn-auth{
    height: 40px;
    padding: 15px;
    border: 0px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0);
    background: var(--base-btn-color);
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 16px;
    font-weight: 700;
    line-height: 10px;
}

#btn-auth:hover{
    background: var(--base-btn-hover-color);
    color: var(--base-text-color);
    box-shadow: var(--base-btn-hover-color) 0.5px 2px 20px;
}

#div-with-auth-and-reg-button{
    display: flex;
    align-items: center;
    margin-left: auto;
}

#div-create-note {
    max-height: 150px;
    display: flex;
    flex-direction: column;
}

#btn-edit-note {
    display: flex;
    margin-top: 10px;
    width: 25px;
    height: 25px;
    border: none;
    background: none;
    cursor: pointer;
}

#btn-delete-note{
    display: flex;
    background: var(--delete-btn-color);
    color: var(--text-color);
    font-family: var(--base-font-family);
    outline: none;
    border: none;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    min-height: 40px;
    padding: 10px;
    margin-right: 10px;
}

#btn-delete-note:hover{
    background: var(--delete-btn-color);
    color: var(--text-color);
    box-shadow: var(--delete-btn-color) 0.5px 2px 20px;
}
@keyframes openAside {
    0% { width: 80px; }
    100% { width: 350px; }
}

#div-text-note {
    display: block;
    width: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
    overflow-wrap: break-word;
    min-height: fit-content;
    padding: 8px;
    border: none;
    border-radius: 0px 0px 8px 8px;
    font-family: var(--base-font-family);
    font-size: 16px;
    color: var(--base-text-color);
    background: var(--base-note-color);
    resize: none;
}

#auto-resizing-textarea {
    width: 100%;
    min-height: 40px;
    overflow: hidden;
    border-radius: 10px;
    border: var(--base-border-input);
    resize: none;
    box-sizing: border-box;
    padding: 15px;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    color: var(--base-text-color);
    min-height: 40px;
    background: none;
    font-family: var(--base-font-family);
}

@media screen and (min-width: 1100px){
    #companyName{
        display: flex;
        max-width: 200px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

@media (max-width: 499px) {
    .sidebar {
        visibility: visible !important;
        position: absolute;
        width: fit-content;
        animation: openAside 0.3s forwards;
        display: flex;
        left: 20px;
        top: 20px;
        bottom: 20px;
        flex-direction: column;
        background-color: var(--sidebar-hover-color);
        box-shadow: inset 0px 0px 100px 5px #ffffff13;
        backdrop-filter: blur(10px);
        z-index: 10;
        border-radius: 15px;
        width: fit-content;
    }
    .sidebar span {
        opacity: 1;
        transform: translateX(0);
    }
    main {
        padding-left: 0px !important;
        width: 100% !important;
    }
    #btn-open-asidebar-icon {
        display: flex;
        background: none;
        border: none;
        cursor: pointer;
        width: 35px;
        height: 35px;
        margin-top: 10px;
        margin-left: auto;
        margin-right: 20px;
    }
    #btn-modal-open{
        display: flex;
        max-width: 200px;
        max-height: 100px;
        width: 45px;
        margin-right: 1px;
        margin-top: -2px;
    }
    #div-with-auth-and-reg-button{
        margin-left: -5px;
    }
    #open-asidebar-icon{
        width: 35px;
        height: 35px;
    }
    #btn-closed-asidebar-icon{
        background: none;
        border: none;
        cursor: pointer;
        width: 35px;
        height: 35px;
        align-self: center;
        position: absolute;
        top: 25px;
        left: 20px;
        z-index: 2;
    }
}

@media (width > 499px) {
    #btn-closed-asidebar-icon{
        display: none;
    }
    #btn-open-asidebar-icon {
        display: none;
    }
}

.header{
    position: absolute;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--base-header-color);
    width: 100%;
    height: 65px;
    box-shadow: 0 4px 20px rgba(31, 31, 31, 0.596);
    z-index: 2;
}

#project-name{
    display: flex;
}

#logo-tz{
    height: 50px;
    margin-left: 10px;
}

.div-main-btn-sidebar{
    display: flex;
    gap: 30px;
    flex-direction: column;
    padding: 20px;
}

@media (max-width: 499px) {
    .div-main-btn-sidebar{
        display: flex;
        gap: 15px;
        flex-direction: column;
        padding: 5px 20px 5px 20px;
    }
    #redirectProfile {
        margin-top: 5px;
    }
    .Number-task{
        margin-top: 5px;
    }
    .aside-line{
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .sidebar {
        height: 87vh;
    }
}

button:active {
    transform: translateY(2px)
}

#input-note-link{
    width: 97%;
    height: 30px;
    margin-top: 10px;
    overflow: hidden;
    font-weight: 500;
    border-radius: 8px;
    border: var(--base-border-input);
    resize: none;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    font-size: 14px;
    outline: none;
    color: var(--base-text-color);
    background: none;
    font-family: var(--base-font-family);
}

#select-time-hh{
    width: 50px;
    height: 38px;
    overflow: hidden;
    border-radius: 8px;
    border: var(--base-border-input);
    resize: none;
    padding-left: 5px;
    font-size: 20px;
    outline: none;
    color: var(--base-text-color);
    margin-left: 10px;
    margin-top: 10px;
    background: none;
    font-weight: bold;
    font-family: var(--base-font-family);
    cursor: pointer;
}

#select-time-mm{
    width: 50px;
    height: 38px;
    overflow: hidden;
    border-radius: 8px;
    border: var(--base-border-input);
    resize: none;
    padding-left: 5px;
    font-size: 20px;
    outline: none;
    margin-right: auto;
    color: var(--base-text-color);
    background: none;
    font-weight: bold;
    font-family: var(--base-font-family);
    margin-top: 10px;
    cursor: pointer;
}

#label-time-hh-mm{
    font-weight: bold;
    color: var(--base-text-color);
    font-size: 30px;
    margin-top: 7px;
}

#hint-for-create-note{
    display: flex;
    max-width: 580px;
    overflow: hidden;
    color: var(--hint-note-color);
    font-size: 12px;
    font-family: var(--base-font-family);
}

#select-time-mm::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
}
#select-time-mm::-webkit-scrollbar-track {
    background: #8b8484;
}
#select-time-mm::-webkit-scrollbar-thumb {
    background: #5d5e8a;
}
#select-time-hh::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
}
#select-time-hh::-webkit-scrollbar-track {
    background: #8b8484;
}
#select-time-hh::-webkit-scrollbar-thumb {
    background: #5d5e8a;
}

#note-date-time{
    color: var(--note-date-time-color);
    font-size: 12px;
    font-family: var(--base-font-family);
}

#note-link{
    display: flex;
    color: var(--note-link-color);
    font-size: 12px;
    font-family: var(--base-font-family);
    margin-left: auto;
}

#div-with-link-and-datetime-note{
    display: flex;
    padding-top: 6px;
    padding-left: 8px;
    padding-bottom: 4px;
    margin-top: 10px;
    border-radius: 8px 2px 0px 0px;
    box-sizing: border-box;
    color: var(--base-text-color);
    background: var(--base-note-color);
}

.Overlay-for-notes {
    display: flex;
    justify-content: flex-end;
    background: none;
    position: fixed;
    top: 60px;
    right: 0;
    z-index: 10;
    width: fit-content;
}
  
.Modal-for-notes {
    display: flex;
    flex-direction: column;
    animation: slideInRight 0.5s forwards;
    background: none;
    background-color: var(--base-modal-color);
    box-shadow: inset 0px 0px 15px 5px var(--base-modal-color);
    backdrop-filter: blur(10px);
    overflow: hidden;
    border-radius: 20px;
    padding: 20px;
    height: 80vh;
    width: fit-content;
    outline: none;
}

#btn-open-create-note{
    display: flex;
    background: var(--create-btn-color);
    border: none;
    color: var(--text-color);
    font-size: 19px;
    font-family: var(--base-font-family);
    font-weight: 600;
    padding: 10px 15px 10px 15px;
    outline: none;
    cursor: pointer;
    align-self: center;
    border-radius: 20px;
}

#btn-create-note-accept{
    display: flex;
    min-height: 40px;
    overflow: hidden;
    margin-top: 10px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    color: var(--text-color);
    padding: 10px;
    border: none;
    cursor: pointer;
    align-items: center;
    font-family: var(--base-font-family);
    align-self: center;
    background: var(--create-btn-color);
}

#btn-create-note{
    display: flex;
    min-height: 40px;
    overflow: hidden;
    margin-top: 10px;
    border-radius: 8px;
    border: var(--base-border-input);
    font-weight: 600;
    font-size: 16px;
    color: var(--base-text-color);
    padding: 10px;
    cursor: pointer;
    align-items: center;
    font-family: var(--base-font-family);
    align-self: center;
    background: none;
}

#title-create-note{
    display: flex;
    color: var(--base-text-color);
    font-size: 22px;
    justify-content: center;
    margin-bottom: 10px;
    font-family: var(--base-font-family);
}

@keyframes slideInRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}