.overlay-task-calendar {
    display: flex;
    justify-content: flex-end;
    background: var(--base-modal-color);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    z-index: 10;
    width: 100%;
}

.modal-task-calendar {
    display: flex;
    flex-direction: column;
    animation: slideInRight 0.5s forwards;
    background: transparent;
    background-color: var(--task-right-element-color);
    box-shadow: var(--task-right-element-shadow);
    backdrop-filter: blur(10px);
    overflow: hidden;
    border-radius: 20px;
    padding: 20px;
    max-height: 60vh;
    width: fit-content;
    outline: none;
}

#sheduler-quick-info-custom{
    text-wrap: wrap;
    word-wrap: break-word;
}


.dhx_cal_event.priority-low-sh {
    background: rgb(100, 120, 214) !important;
}

.dhx_cal_event.priority-medium-sh {
    background: rgb(220, 136, 0) !important;
}

.dhx_cal_event.priority-hight-sh {
    background: rgb(175, 52, 52) !important;
}

.dhx_cal_event_line.priority-low-sh {
    background: rgb(100, 120, 214) !important;
}

.dhx_cal_event_line.priority-medium-sh {
    background: rgb(220, 136, 0) !important;
}

.dhx_cal_event_line.priority-hight-sh {
    background: rgb(175, 52, 52) !important;
}

.dhx_cal_event_line.dhx_cal_event_line_start.priority-low-sh {
    background: rgb(100, 120, 214) !important;
}

.dhx_cal_event_line.dhx_cal_event_line_start.priority-medium-sh {
    background: rgb(220, 136, 0) !important;
}

.dhx_cal_event_line.dhx_cal_event_line_start.priority-high-sh {
    background: rgb(175, 52, 52) !important;
}

.dhx_cal_event_line.dhx_cal_event_line_end.priority-low-sh {
    background: rgb(100, 120, 214) !important;
}

.dhx_cal_event_line.dhx_cal_event_line_end.priority-medium-sh {
    background: rgb(220, 136, 0) !important;
}

.dhx_cal_event_line.dhx_cal_event_line_end.priority-high-sh {
    background: rgb(175, 52, 52) !important;
}

.dhx_cal_today_button {
    color: var(--base-text-color) !important;
    border-color: rgb(44, 45, 55) !important;
}

.dhx_cal_tab {
    color: var(--base-text-color) !important;
    border-color: rgb(44, 45, 55) !important;
}

.dhx_cal_tab_segmented {
    color: var(--base-text-color) !important;
    border-color: rgb(44, 45, 55) !important;
}

.dhx_cal_tab.active {
    background-color: #5d5f79 !important;
    color: var(--base-text-color) !important;
}

.dhx_cal_tab:hover {
    background-color: #5d5f79 !important;
    color: var(--base-text-color) !important;
    border-color: rgb(44, 45, 55) !important;
}

.dhx_cal_tab.active:hover {
    background-color: #5d5f79 !important;
}

.dhx_cal_tab_standalone.active {
    background-color: #795d5d !important;
    border-color: rgb(44, 45, 55) !important;
}

.dhx_cal_today_button:hover {
    background-color: #5d5f79 !important;
}

.dhx_scale_bar a {
    font-family: var(--base-font-family) !important;
    color: var(--base-text-color) !important;
}

.dhx_cal_event {
    cursor: pointer;
    background: #E06666 !important;
}

.dhx_cal_container ::-webkit-scrollbar {
    width: 8px !important;
}

.dhx_cal_container ::-webkit-scrollbar-thumb {
    background: rgb(44, 45, 55) !important;
    border-radius: 4px !important;
}

.dhx_month_head a {
    font-family: var(--base-font-family) !important;
    color: var(--base-text-color) !important;
}

.dhx_cal_container {
    width: 98%;
    height: 90vh;
}

:root {
    --dhx-scheduler-font-family: var(--base-font-family) !important;
    --dhx-scheduler-font-size: 16px !important;
 
    --dhx-scheduler-base-colors-primary: var(--dhx-custom-scheduler-base-colors-primary);
    --dhx-scheduler-base-colors-warning: var(--dhx-custom-scheduler-base-colors-warning);
    --dhx-scheduler-base-colors-error: var(--dhx-custom-scheduler-base-colors-error);
    --dhx-scheduler-base-colors-error-text: var(--dhx-custom-scheduler-base-colors-error-text);
    --dhx-scheduler-base-colors-success: var(--dhx-custom-scheduler-base-colors-success);
    --dhx-scheduler-base-colors-secondary: var(--dhx-custom-scheduler-base-colors-secondary);
    --dhx-scheduler-btn-outline-color: var(--dhx-custom-scheduler-btn-outline-color) !important;
    --dhx-scheduler-btn-outline-border-color: var(--dhx-custom-scheduler-btn-outline-border-color) !important;
    --dhx-scheduler-btn-outline-background: var(--dhx-custom-scheduler-btn-outline-background) !important;
    --dhx-scheduler-btn-outline-border-active: var(--dhx-custom-scheduler-btn-outline-border-active) !important;
    --dhx-scheduler-btn-outline-color-hover: var(--dhx-custom-scheduler-btn-outline-color-hover) !important;
    --dhx-scheduler-btn-outline-border-hover: var(--dhx-custom-scheduler-btn-outline-border-hover) !important;
    --dhx-scheduler-btn-background: var(--dhx-custom-scheduler-btn-background) !important;
    --dhx-scheduler-btn-border-color: var(--dhx-custom-scheduler-btn-border-color) !important;
 
    --dhx-scheduler-base-colors-select: var(--dhx-custom-scheduler-base-colors-select) !important;
    --dhx-scheduler-base-colors-border: var(--dhx-custom-scheduler-base-colors-border) !important;
    --dhx-scheduler-base-colors-icons: var(--dhx-custom-scheduler-base-colors-icons) !important;
 
    --dhx-scheduler-base-colors-disabled: var(--dhx-custom-scheduler-base-colors-disabled);
    --dhx-scheduler-base-colors-readonly: var(--dhx-scheduler-base-colors-icons);
    --dhx-scheduler-base-colors-text-light: var(--dhx-custom-scheduler-base-colors-text-light) !important;
    --dhx-scheduler-base-colors-text-base: var(--dhx-custom-scheduler-base-colors-text-base) !important;
    --dhx-scheduler-base-colors-background: var(--dhx-custom-scheduler-base-colors-background) !important;
 
    --dhx-scheduler-container-background: var(--dhx-scheduler-base-colors-background);
    --dhx-scheduler-container-color: var(--dhx-scheduler-base-colors-text-base);
    --dhx-scheduler-scale-color: var(--dhx-scheduler-container-color);
 
    --dhx-scheduler-base-padding: 4px;
    --dhx-scheduler-border-radius: var(--dhx-scheduler-base-module);
 
    --dhx-scheduler-event-colors-primary: #9b7c7c;
    --dhx-scheduler-event-text-primary: var(--base-text-color);
 
    --dhx-scheduler-toolbar-height: 40px;
 
    --dhx-scheduler-header-border: var(--dhx-scheduler-default-border);
    --dhx-scheduler-halfhour-border: 1px dotted var(--dhx-scheduler-base-colors-border);
 
    /* events */
 
    --dhx-scheduler-event-background-primary: var(--dhx-scheduler-base-colors-primary);
 
    --dhx-scheduler-event-blue: linear-gradient(180deg, #527CFF 0%, #9751FC 100%);
    --dhx-scheduler-event-green: linear-gradient(180deg, #12D979 0%, #1ECDEB 100%);
    --dhx-scheduler-event-violet: linear-gradient(180deg, #D071EF 0%, #EE71D5 100%);
    --dhx-scheduler-event-yellow: linear-gradient(180deg, #FFB725 0%, #FFBB25 31.25%, 
      #FAEA27 100%);
 
    --dhx-scheduler-event-menu-background: var(--dhx-scheduler-popup-background);
    --dhx-scheduler-event-menu-color: var(--dhx-scheduler-base-colors-primary);
 
    --dhx-scheduler-event-background: var(--dhx-scheduler-event-blue);
    --dhx-scheduler-event-border: none;
    --dhx-scheduler-event-color: var(--dhx-scheduler-event-text-primary);
    --dhx-scheduler-event-line-text: var(--dhx-scheduler-container-color);
 
    --dhx-scheduler-event-marker-color: var(--dhx-scheduler-event-background);
 
    --dhx-scheduler-popup-background: var(--dhx-scheduler-container-background);
    --dhx-scheduler-popup-color: var(--dhx-scheduler-container-color);
    --dhx-scheduler-popup-border: none;
    --dhx-scheduler-popup-border-radius: var(--dhx-scheduler-border-radius);

}