#div-for-accept-invite-organization {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}

#button-accept-invite-organization {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
    color: white;
    border: none;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    background: #2c2d37;
    border-radius: 10px;
    font-size: 30px;
    max-height: 55px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease, box-shadow 0.3s ease;
}

#button-accept-invite-organization:hover{
    transform: translateY(5px);
    background: #714d94;
    color: white;
    box-shadow: #684689 0.5px 2px 20px;
}