@import url('./variables.css');

html, body {
  margin-top: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
  overflow: hidden !important;
  background: var(--base-bg-color);
  height: 100% !important;
}

#not-adaptive-device{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  box-shadow: -5px 0px 65px 0px rgb(55, 5, 250);
}

#confirm-not-adaptive-device{
  display: flex;
  border-radius: 10px;
  font-size: 20px;
  height: 40px;
  margin-top: 20px;
  align-items: center;
}

button:active {
  transform: translateY(2px)
}

/* design loader *//* design loader *//* design loader *//* design loader *//* design loader *//* design loader */

.loader {
  width: 65px;
  aspect-ratio: 1;
  position: relative;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  border-radius: 50px;
  box-shadow: 0 0 0 4px inset #21cceb;
  animation: l4 3s infinite;
}
.loader:after {
  animation-delay: -1.75s;
}
@keyframes l4 {
  0% {
    inset: 0 35px 35px 0;
  }
  12.5% {
    inset: 0 35px 0 0;
  }
  25% {
    inset: 35px 35px 0 0;
  }
  37.5% {
    inset: 35px 0 0 0;
  }
  50% {
    inset: 35px 0 0 35px;
  }
  62.5% {
    inset: 0 0 0 35px;
  }
  75% {
    inset: 0 0 35px 35px;
  }
  87.5% {
    inset: 0 0 35px 0;
  }
  100% {
    inset: 0 35px 35px 0;
  }
}

/* design loader *//* design loader *//* design loader *//* design loader *//* design loader *//* design loader */

.loader-file {
  min-width: 45px;
  height: 12px;
  margin-top: 40px;
  background: #21cceb;
  display: inline-block;
  position: relative;
}
.loader-file::after {
  content: '';  
  left: 50%;
  top: -47px;
  transform: translate(-50%, 0);
  position: absolute;
  border: 15px solid transparent;
  border-bottom-color: #21cceb;
  box-sizing: border-box;
  border-radius: 5px;
  animation: bump 0.4s ease-in-out infinite alternate;
}
.loader-file::before {
  content: '';  
  left: 50%;
  bottom: 15px;
  transform: translate(-50%, 0);
  position: absolute;
  width: 12px;
  border-radius: 5px;
  height: 20px;
  background: #21cceb;
  box-sizing: border-box;
  animation: bump 0.4s ease-in-out infinite alternate;
}

@keyframes bump {
  0% {
    transform: translate(-50%, 5px);
  }
  100% {
    transform: translate(-50%, -5px);
  }
}
    
/* design loader *//* design loader *//* design loader *//* design loader *//* design loader *//* design loader */