.hello-page{
  display: flex;
  background: linear-gradient(48.44deg, rgb(255, 126, 95) 3.237%,rgb(130, 74, 176) 35.453%,rgb(123, 95, 255) 69.548%,rgb(143, 105, 229) 99.004%);
  flex-direction: column;
  overflow-x: hidden;
  height: 100vh;
  overflow-y: auto;
  align-items: center;
}

.hello-page::-webkit-scrollbar {
  width: 4px;
  border-radius: 10px;
}
.hello-page::-webkit-scrollbar-track {
  background: rgba(34, 35, 43, 0.6);
}
.hello-page::-webkit-scrollbar-thumb {
  background: #7F8094;
}

#mainText{
  color: var(--text-color);
  font-family: var(--base-font-family);
  font-size: 64px;
  font-weight: 900;
  letter-spacing: 0px;
  text-align: center;
  white-space: nowrap;
  margin-top: 40px;
  text-shadow: #cfcfd4d8 0.5px 2px 5px;
  z-index: 1;
}

#nameOrganization,
#mail,
#phoneNumber{
  width: 340px;
  padding: 15px;
  font-size: 16px;
  color: var(--base-text-color);
  margin-bottom: 10px;
  font-family: var(--base-font-family);
	border-radius: 30px;
  border: var(--base-border-input);
  outline: none;
  background: var(--base-bg-color-input);
}

#nameOrganization::placeholder {
  color: var(--base-placeholder-input);
}

#miniText{
  color: var(--text-color);
  font-family: var(--base-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  justify-content: center;
  align-items: center;
  max-width: 718px;
  margin-top: 15px;
  text-wrap: wrap;
  z-index: 1;
}

.application-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 450px;
  height: 470px;
  padding: 30px 40px;
  box-sizing: border-box;
  border-radius: 20px;	
  z-index: 1;
  box-shadow: 0px 10px 25px 0px rgba(49, 42, 56, 0.589);
  background: var(--base-element-color);
	backdrop-filter: blur(10px);
}

.application-form h2 {
  font-family: var(--base-font-family);
  margin-top: 0;
  margin-bottom: 30px;
  color: var(--base-text-color);
  font-size: 24px;
  text-wrap: wrap;
  text-align: center;
}

.form-group {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 20px;
  align-items: center;
}

#maintextinform{
  margin-bottom: 20px;
}

input[type="mail"]::placeholder,
input[type="phoneNumber"]::placeholder{
  color: var(--base-placeholder-input);
}

#send{
  height: 40px;
  padding: 15px;
  border: 0px;
  border-radius: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0);
  background: linear-gradient(48.44deg, rgb(255, 126, 95) 3.237%,rgb(130, 74, 176) 35.453%,rgb(123, 95, 255) 69.548%,rgb(143, 105, 229) 99.004%);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--text-color);
  font-family: var(--base-font-family);
  font-size: 18px;
  font-weight: 700;
  line-height: 10px;
}

#main-txt-div{
  display: flex;
  margin-top: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 799px) {
  #mainText{
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 50px;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: center;
    white-space: nowrap;
    text-shadow: #98989b 0.5px 2px 20px;
    z-index: 1;
    margin-top: -100px;
    margin-right: 20px;
  }

  #miniText{
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 600px;
    margin-top: 10px;
    text-wrap: wrap;
    margin-right: 25px;
    z-index: 1;
  }

  .application-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 400px;
    height: 450px;
    padding: 30px 40px;
    box-sizing: border-box;
    border-radius: 20px;	
    background: var(--base-element-color);
    z-index: 1;
    margin-bottom: -80px;
    margin-top: 30px;
    margin-right: 25px;
  }

  #nameOrganization,
  #mail,
  #phoneNumber{
    width: 300px;
  }

  #main-txt-div{
    margin-top: 230px;
  }
}

@media (max-width: 499px) {
  #mainText{
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: center;
    white-space: nowrap;
    text-shadow: #98989b 0.5px 2px 20px;
    z-index: 1;
    margin-top: 0px;
    margin-right: 0px;
  }

  #nameOrganization,
  #mail,
  #phoneNumber{
    width: 250px;
  }

  #main-txt-div{
    display: flex;
    margin-top: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #miniText{
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 300px; 
    margin-top: 10px;
    text-wrap: wrap;
    margin-right: 10px;
    z-index: 1;
  }

  .application-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 350px;
    height: 450px;
    padding: 30px 40px;
    box-sizing: border-box;
    border-radius: 20px;	
    background: var(--base-element-color);
    z-index: 1;
    margin-bottom: -80px;
    margin-top: 40px;
    margin-right: 0px;
  }

  #maintextinform{
    font-size: 20px;
  }

  #send{
    font-size: 16px;
  }
}

.div-info-color-bg{
  position: relative;
  margin-top: 20vh;
  width: 100%;
  flex-direction: column;
  background-color: var(--hello-background-color);
}

.div-info-lists-tz{
  display: flex;
  margin-top: 90px;
}

#text-info-lists{
  font-family: var(--base-font-family);
  color: var(--base-text-color);
  font-size: 20px;
  max-width: 700px;
  text-align: left;
  margin-left: auto;
  margin-right: 15vh;
  z-index: 1;
  align-self: center;
}  

.div-info-filtr{
  display: flex;
  z-index: 1;
  margin-top: 70px;
  margin-bottom: 40px;
}

#img-info-filtr{
  display: flex;
  max-width: 800px;
  max-height: 500px;
  align-self: center;
  order: 2;
  z-index: 1;
  margin-right: 15vh;
  margin-left: 5vh;
}

#gradient-two{
  width: 120vh; 
  position: absolute;
  height: 120vh;
  border-radius: 50%;
  margin-left: auto;
  right: -60vh;
  z-index: 0;
  margin-top: -55vh;
  background: radial-gradient(rgba(203, 74, 186, 0.342), rgba(68, 72, 203, 0.233), rgba(36, 117, 118, 0.103), rgba(254, 180, 123, 0) 60%);
  pointer-events: none; 
}

#text-info-filtr{
  font-family: var(--base-font-family);
  color: var(--base-text-color);
  font-size: 20px;
  max-width: 700px;
  text-align: left;
  align-self: center;
  order: 1;
  z-index: 1;
  margin-right: auto;
  margin-left: 15vh;
}

#img-info-lists{
  display: flex;
  max-width: 800px;
  z-index: 1;
  max-height: 500px;
  margin-right: 5vh;
  margin-left: 15vh;
}

#gradient-one{
  width: 120vh; 
  position: absolute;
  height: 120vh;
  border-radius: 50%;
  margin-right: auto;
  margin-left: -40vh;
  margin-top: -10vh;
  z-index: 0;
  background: radial-gradient(rgba(203, 74, 186, 0.527), rgba(6, 67, 233, 0.192), rgba(36, 117, 118, 0.103), rgba(254, 180, 123, 0) 60%);
  pointer-events: none; 
}

.div-info-user{
  display: flex;
  z-index: 1;
  margin-top: 70px;
  margin-bottom: 90px;
}

#gradient-three{
  position: absolute; 
  margin-right: auto;
  margin-left: 52vh;
  margin-top: -35vh;
  width: 85vh; 
  height: 85vh;
  border-radius: 50%;
  background: radial-gradient(rgba(203, 74, 186, 0.445), rgba(68, 72, 203, 0.164), rgba(36, 117, 118, 0.103), rgba(254, 180, 123, 0) 60%);
  pointer-events: none; 
  z-index: 0; 
}

#img-info-user{
  display: flex;
  max-width: 800px;
  z-index: 1;
  max-height: 500px;
  align-self: center;
  margin-right: 5vh;
  margin-left: 15vh;
}

#text-info-user{
  font-family: var(--base-font-family);
  color: var(--base-text-color);
  font-size: 20px;
  margin-left: auto;
  z-index: 1;
  margin-right: 15vh;
  max-width: 700px;
  align-self: center;
}

.carousel-info{
  display: flex;
  align-items: center;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

#img-carousel{
  margin-left: 15px;
  z-index: 1;
  margin-right: 15px;
  height: 310px;
}

@media (max-width: 1299px) {
  .div-info-lists-tz{
    margin-top: 40px;
    flex-direction: column;
  }

  #text-info-lists{
    font-family: var(--base-font-family);
    margin-left: 0;
    margin-right: 0;
    z-index: 1;
    color: var(--base-text-color);
    font-size: 18px;
    max-width: 700px;
    text-align: center;
    align-self: center;
  }

  #img-info-lists{
    display: flex;
    max-width: 800px;
    max-height: 500px;
    z-index: 1;
    align-self: center;
    margin-left: 0px;
    margin-right: 0;
  }

  .div-info-filtr{
    margin-top: 20px;
    flex-direction: column;
  }

  #text-info-filtr{
    font-family: var(--base-font-family);
    margin-left: 0;
    margin-right: 0;
    color: var(--base-text-color);
    z-index: 1;
    font-size: 18px;
    width: 900px;
    text-align: center;
    align-self: center;
  }

  #img-info-filtr{
    display: flex;
    max-width: 800px;
    max-height: 500px;
    align-self: center;
    z-index: 1;
    margin-left: 0px;
    margin-right: 0;
  }

  .div-info-user{
    margin-top: 20px;
    flex-direction: column;
  }

  #text-info-user{
    font-family: var(--base-font-family);
    margin-left: 0;
    margin-right: 0;
    color: var(--base-text-color);
    font-size: 18px;
    width: 900px;
    text-align: center;
    align-self: center;
  }

  #img-info-user{
    display: flex;
    max-width: 800px;
    max-height: 500px;
    align-self: center;
    margin-left: 0px;
    margin-right: 0;
    order: 1;
  }

  #img-carousel{
    margin-left: 10px;
    margin-right: 10px;
    height: 230px;
  }
}

@media (max-width: 799px) {
  .div-info-lists{
    margin-top: 40px;
    flex-direction: column;
  }

  #text-info-lists{
    font-family: var(--base-font-family);
    margin-top: 15px;
    color: var(--base-text-color);
    font-size: 16px;
    margin-left: 0;
    margin-right: 0;
    max-width: 600px;
    text-align: center;
    align-self: center;
  }

  #img-info-lists{
    display: flex;
    max-width: 499px;
    max-height: 500px;
    align-self: center;
    margin-left: 0px;
    margin-right: 0;
  }

  .div-info-filtr{
    margin-top: 20px;
    margin-bottom: 0;
    flex-direction: column;
  }

  #text-info-filtr{
    font-family: var(--base-font-family);
    margin-top: 20px; 
    color: var(--base-text-color);
    font-size: 16px;
    width: 500px;
    text-align: center;
    align-self: center;
  }

  #img-info-filtr{
    display: flex;
    max-width: 499px;
    max-height: 500px;
    align-self: center;
    margin-left: 0px;
    margin-right: 0;
  }

  .div-info-user{
    margin-top: 20px;
    flex-direction: column;
  }

  #img-info-user{
    display: flex;
    max-width: 499px;
    max-height: 500px;
    align-self: center;
    margin-left: 0px;
    margin-right: 0;
  }

  #text-info-user{
    font-family: var(--base-font-family);
    margin-top: 20px; 
    color: var(--base-text-color);
    font-size: 16px;
    width: 500px;
    text-align: center;
    align-self: center;
  }

  .carousel-info{
    margin-top: 30px;
    margin-bottom: 30px;
  }

  #img-carousel{
    margin-left: 5px;
    margin-right: 5px;
    height: 130px;
  }
}

@media (max-width: 499px) {
  .div-info-lists-tz{
    margin-top: 50px;
    flex-direction: column;
  }

  #text-info-lists{
    font-family: var(--base-font-family);
    margin-top: 30px;
    color: var(--base-text-color);
    font-size: 14px;
    width: 400px;
    text-align: center;
    align-self: center;
  }

  #img-info-lists{
    display: flex;
    max-width: 340px;
    max-height: 500px;
    align-self: center;
    margin-left: 0px;
  }

  .div-info-filtr{
    margin-top: 50px;
    flex-direction: column;
  }

  #text-info-filtr{
    font-family: var(--base-font-family);
    margin-top: 30px; 
    color: var(--base-text-color);
    font-size: 14px;
    width: 370px;
    text-align: center;
    align-self: center;
  }

  #img-info-filtr{
    display: flex;
    max-width: 340px;
    max-height: 500px;
    align-self: center;
    margin-left: 0px;
  }

  #img-info-user{
    display: flex;
    max-width: 340px;
    max-height: 500px;
    align-self: center;
    margin-left: 0px;
    margin-right: 0;
  }

  #text-info-user{
    font-family: var(--base-font-family);
    margin-top: 30px; 
    color: var(--base-text-color);
    font-size: 14px;
    width: 370px;
    text-align: center;
    align-self: center;
  }

  .carousel-info{
    margin-top: 30px;
    display: flex;
    margin-bottom: 30px;
    flex-wrap: wrap; 
    gap: 15px; 
    margin-left: 30px;
  }

  #img-carousel{
    height: 180px;
    margin-left: 0;
    margin-right: 0;
    width: calc(45% - 0px);
  }
}