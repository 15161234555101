@import url('https://fonts.googleapis.com/css2?family=Oswald&family=PT+Serif:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.main-container {
  font-family: 'Roboto', sans-serif;
	min-width: 100%;
  max-width: 100px !important;
	margin: 0 auto;
}

.ck-editor__editable,
.ck-editor__editable:focus,
.ck-editor__editable:empty,
.ck-editor__editable:empty::before {
  min-height: 150px !important;
  border: 1px Solid rgb(71, 72, 88) !important;
  color: var(--base-text-color);
  box-shadow: none !important;
}
/*таблица внутри ск*/
.ck-editor__nested-editable {
  padding: 5px;
  border: 1px solid var(--base-text-color) !important;
}

.ck-balloon-rotator {
  background-color: var(--base-element-color) !important;
}

.ck-editor {
	width: 100% !important;
	display: block !important;
}

#div-ckeditor {
    display: flex;
    flex-direction: column;
    min-width: 100%;
	align-items: stretch;
	border-radius: 15px;
	overflow: hidden;
  border-radius: 20px;
  border: 1px Solid rgb(71, 72, 88);
}

.ck-content {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  min-width: 100%;
  word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
  min-width: 795px;
  max-width: 795px;
}

.ck-content h3.category {
  font-family: 'Oswald';
  font-size: 20px;
  font-weight: bold;
  color: var(--base-text-color);
  letter-spacing: 10px;
  margin: 0;
  padding: 0;
}

.ck-content h2.document-title {
  font-family: 'Oswald';
  font-size: 50px;
  font-weight: bold;
  color: var(--base-text-color);
  margin: 0;
  padding: 0;
  border: 0;
}

.ck-content h3.document-subtitle {
  font-family: 'Oswald';
  font-size: 20px;
  color: var(--base-text-color);
  margin: 0 0 1em;
  font-weight: bold;
  padding: 0;
}

.ck-content p.info-box {
  --background-size: 30px;
  --background-color: #e91e63;
  padding: 1.2em 2em;
  border: 1px solid var(--background-color);
  background: linear-gradient(
      135deg,
      var(--background-color) 0%,
      var(--background-color) var(--background-size),
      transparent var(--background-size)
    ),
    linear-gradient(
      135deg,
      transparent calc(100% - var(--background-size)),
      var(--background-color) calc(100% - var(--background-size)),
      var(--background-color)
    );
  border-radius: 10px;
  margin: 1.5em 2em;
  box-shadow: 5px 5px 0 #ffe6ef;
}

.ck-content blockquote.side-quote {
  font-family: 'Oswald';
  font-style: normal;
  float: right;
  width: 35%;
  position: relative;
  border: 0;
  overflow: visible;
  z-index: 1;
  margin-left: 1em;
}

.ck-content blockquote.side-quote::before {
  content: '“';
  position: absolute;
  top: -37px;
  left: -10px;
  display: block;
  font-size: 200px;
  color: #e7e7e7;
  z-index: -1;
  line-height: 1;
}

.ck-content blockquote.side-quote p {
  font-size: 2em;
  line-height: 1;
}

.ck-content blockquote.side-quote p:last-child:not(:first-child) {
  font-size: 1.3em;
  text-align: right;
  color: #555;
}

.ck-content span.marker {
  background: yellow;
  color: black;
}

.ck-content span.spoiler {
  background: var(--base-text-color);
  color: var(--base-text-color);
  border-radius: 4px;
}

.ck-content span.spoiler:hover {
  background: none;
  color: var(--base-text-color);
}

.ck-content pre.fancy-code {
  border: 0;
  margin-left: 2em;
  margin-right: 2em;
  border-radius: 10px;
}

.ck-content pre.fancy-code::before {
  content: '';
  display: block;
  height: 13px;
  background: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NCAxMyI+CiAgPGNpcmNsZSBjeD0iNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGMzZCNUMiLz4KICA8Y2lyY2xlIGN4PSIyNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGOUJFNEQiLz4KICA8Y2lyY2xlIGN4PSI0Ny41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiM1NkM0NTMiLz4KPC9zdmc+Cg==);
  margin-bottom: 8px;
  background-repeat: no-repeat;
}

.ck-content pre.fancy-code-dark {
  background: #272822;
  color: #fff;
  box-shadow: 5px 5px 0 #0000001f;
}

.ck-content pre.fancy-code-bright {
  background: #dddfe0;
  color: #000;
  box-shadow: 5px 5px 0 #b3b3b3;
}

.ck-content .text-tiny {
  font-size: 0.7em;
}

.ck-content .text-small {
  font-size: 0.85em;
}

.ck-content .text-big {
  font-size: 1.4em;
}

.ck-content .text-huge {
  font-size: 1.8em;
}

a:-webkit-any-link {
  color: #ff0000;
  cursor: pointer;
  text-decoration: underline;
}

a:any-link {
  color: #ff0000;
  cursor: pointer;
  text-decoration: underline;
}

.ck-content input[type="checkbox"] {
  border: 1px solid #ffffff !important;
  cursor: pointer;
  border-radius: 2px;
  align-items: center;
}

:root {
  /* Overrides the border radius setting in the theme. */
  --ck-border-radius: 4px;

  /* Overrides the default font size in the theme. */
  --ck-font-size-base: 14px;

  /* Helper variables to avoid duplication in the colors. */
  --ck-custom-background: none;
  --ck-custom-border: none;
  --ck-custom-white: hsl(0, 0%, 100%);

  /* -- Overrides generic colors. ------------------------------------------------------------- */

  --ck-color-base-foreground: var(--ck-custom-background);
  --ck-color-base-background: var(--ck-custom-background);
  --ck-color-focus-border: hsl(208, 90%, 62%);
  --ck-color-text: var(--base-text-color);
  --ck-color-shadow-drop: hsla(0, 0%, 0%, 0.2);
  --ck-color-shadow-inner: hsla(0, 0%, 0%, 0.1);
  --ck-color-link: hsl(208, 90%, 62%) !important;

  /* -- Overrides the default .ck-button class colors. ---------------------------------------- */

  --ck-color-button-default-background: var(--ck-custom-background);
  --ck-color-button-default-hover-background: var(--ck-custom-color-button-default-hover-background);
  --ck-color-button-default-active-background: var(--ck-custom-color-button-default-active-background);
  --ck-color-button-default-active-shadow: var(--ck-custom-color-button-default-active-shadow);
  --ck-color-button-default-disabled-background: var(--ck-custom-background);

  --ck-color-button-on-background: var(--ck-custom-foreground);
  --ck-color-button-on-hover-background: var(--ck-custom-color-button-on-hover-background);
  --ck-color-button-on-active-background: var(--ck-custom-color-button-on-active-background);
  --ck-color-button-on-active-shadow: var(--ck-custom-color-button-on-active-shadow);
  --ck-color-button-on-disabled-background: var(--ck-custom-foreground);

  --ck-color-button-action-background: hsl(168, 76%, 42%);
  --ck-color-button-action-hover-background: hsl(168, 76%, 38%);
  --ck-color-button-action-active-background: hsl(168, 76%, 36%);
  --ck-color-button-action-active-shadow: hsl(168, 75%, 34%);
  --ck-color-button-action-disabled-background: hsl(168, 76%, 42%);
  --ck-color-button-action-text: var(--ck-color-text);

  --ck-color-button-save: hsl(120, 100%, 46%);
  --ck-color-button-cancel: hsl(15, 100%, 56%);

  /* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */

  --ck-color-dropdown-panel-background: var(--task-modal-element-color);
  --ck-color-dropdown-panel-border: var(--ck-custom-foreground);

  /* -- Overrides the default .ck-dialog class colors. ----------------------------------- */

  --ck-color-dialog-background: var(--ck-custom-color-dialog-background);
  --ck-color-dialog-form-header-border: var(--ck-custom-border);

  /* -- Overrides the default .ck-splitbutton class colors. ----------------------------------- */

  --ck-color-split-button-hover-background: var(--ck-color-button-default-hover-background);
  --ck-color-split-button-hover-border: var(--ck-custom-foreground);

  /* -- Overrides the default .ck-input class colors. ----------------------------------------- */

  --ck-color-input-background: var(--ck-custom-background);
  --ck-color-input-border: var(--ck-custom-border);
  --ck-color-input-text: var(--ck-color-text);
  --ck-color-input-disabled-background: hsl(255, 4%, 21%);
  --ck-color-input-disabled-border: var(--ck-custom-border);
  --ck-color-input-disabled-text: hsl(0, 0%, 78%);
  --ck-accessibility-help-dialog-code-background-color: var(--ck-custom-accessibility-help-dialog-code-background-color);

  /* -- Overrides the default .ck-labeled-field-view class colors. ---------------------------- */

  --ck-color-labeled-field-label-background: var(--ck-custom-background);

  /* -- Overrides the default .ck-list class colors. ------------------------------------------ */

  --ck-color-list-background: var(--ck-custom-background);
  --ck-color-list-button-hover-background: var(--ck-color-base-foreground);
  --ck-color-list-button-on-background: var(--ck-color-base-active);
  --ck-color-list-button-on-background-focus: var(--ck-color-base-active-focus);
  --ck-color-list-button-on-text: var(--ck-color-base-background);

  /* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */

  --ck-color-panel-background: var(--ck-custom-background);
  --ck-color-panel-border: var(--ck-custom-border);

  /* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */

  --ck-color-toolbar-background: var(--ck-custom-background);
  --ck-color-toolbar-border: var(--ck-custom-border);

  /* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */

  --ck-color-tooltip-background: var(--ck-custom-color-dialog-background);
  --ck-color-tooltip-text: var(--base-text-color);

  /* -- Overrides the default colors used by the ckeditor5-image package. --------------------- */

  --ck-color-image-caption-background: hsl(0, 0%, 100%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);

  /* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */

  --ck-color-widget-blurred-border: var(--ck-custom-border);
  --ck-color-widget-hover-border: hsl(43, 100%, 68%);
  --ck-color-link-default: hsl(0, 80%, 53%);
  --ck-color-link: hsl(0, 80%, 53%);

  .ck.ck-sticky-panel__content {
    border: none !important;
  }
}