.checkbox-label {
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px 20px;
    cursor: pointer;
    color: var(--base-text-color);
    transition: background 0.3s ease;
    font-family: var(--base-font-family);
    overflow: hidden;
    text-wrap: nowrap;
    white-space: nowrap;
}

.checkbox-label.active {
    background: none;
}

.checkbox-label .indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: var(--base-checkbox-side-indicator);
    transition: background 0.3s ease;
}

#checkbox-label-img {
   width: 20px;
   height: 20px;
   margin-right: 5px;
}

.checkbox-label.active .indicator {
    background: var(--base-checkbox-side-active-indicator);
}

.checkbox-label input[type="checkbox"] {
    display: none;
}

.overlay-modal-create-task{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    overflow-y: scroll;
    outline: none;
}

.modal-create-task{
    display: flex;
    padding-left: 20px;
    background: var(--task-modal-element-color);
    box-shadow: var(--task-right-element-shadow);
    animation: slideInRight 0.5s forwards;
    backdrop-filter: blur(5px);
    max-width: 800px;
    min-width: 400px;
    border-radius: 10px;
    height: fit-content;
    outline: none;
}

.overlay-modal-create-task::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
}
.overlay-modal-create-task::-webkit-scrollbar-track {
    background: var(--task-modal-element-color);
}
.overlay-modal-create-task::-webkit-scrollbar-thumb {
    background: #7F8094;
}

#component-task-image-author{
    width: 30px;
    height: 30px;
    border-radius: 20px;
}

#mini-card-completed{
    display: flex;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: auto;
    background: green;
}

#div-for-history-column{
    display: flex;
    flex-direction: column;
    transition: background-color 0.3s ease, transform 0.1s ease;
}

#div-for-history-name-and-dateupdate{
    display: flex;
    flex-direction: row;
}

#label-username{
    font-family: var(--base-font-family);
    font-size: 18px;
    color: var(--base-text-color);
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    margin-left: 5px;
}

#div-for-history-stroke{
    display: flex;
    width: 100%;
    flex-direction: column;
}

#label-dateupdate{
    color: var(--history-hint-color);
    font-family: var(--base-font-family);
    font-size: 12px;
    font-weight: 500;
    margin-left: auto;
}

#div-for-main-history-information-detail-task{
    display: flex;
    flex-direction: column;
    padding-bottom: 0px;
    margin-top: 5px;
}

#arrow-icon{
    margin-left: 10px;
    margin-right: 10px;
}

#text-change{
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 16px;
    font-weight: 500;
    margin-left: 5px;
    text-wrap: wrap;
    word-break: break-all;
}

.modal-form-comment{
    max-width: 500px;
}

#text-comment {
    display: flex;
    color: var(--base-text-color);
    resize: none;
    border-radius: 20px;
    border: var(--base-border-input);
    background: transparent;
    overflow: hidden;
    font-size: 19px;
    font-weight: bold;
    padding-top: 10px;
    padding-left: 10px;
    outline: none;
    width: 100%;
    margin-right: 10px;
    max-height: 135px;
    font-family: var(--base-font-family);
}

#text-comment::placeholder {
    color: var(--base-placeholder-input);
}

#div-with-comments{
    display: flex;
    flex-direction: column;
    transition: background-color 0.3s ease, transform 0.1s ease;
}

.comment-left {
    display: flex;
    margin-top: 10px;
    margin-right: auto;
    padding: 5px;
    border-radius: 2px 10px 10px 10px;
    background: var(--comment-left-color);
    box-shadow: inset 0px 0px 15px 5px var(--comment-left-color);
    backdrop-filter: blur(10px);
}
  
.comment-right {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    margin-left: auto;
    border-radius: 10px 2px 10px 10px;
    padding: 2px;
    background: var(--comment-right-color);
}

#comment-text-button{
    white-space: pre-wrap;
    display: flex;
    background: none;
    align-items: center;
    border: none;
    color: var(--base-text-color);
    font-size: 14px;
    font-weight: 400;
    min-height: 35px;
    max-width: 250px;
    text-align: left;
    text-wrap: wrap;
    word-break: break-word;
    cursor: pointer;
    font-family: var(--base-font-family);
}

.image-comment-user{
    width: 30px;
    height: 30px;
    border: none;
    box-sizing: border-box;
	border-radius: 10px;
}

#comment-image{
    display: flex;
    height: fit-content;
}

#comment-image:first-child{
    margin-top: auto;
    height: fit-content;
}

#create-report-subtask{
    background: var(--base-btn-color);
    margin-left: 10px;
    border: none;
    color: var(--text-color);
    font-size: 16px;
    font-family: var(--base-font-family);
    font-weight: 400;
    padding: 10px 15px 10px 15px;
    outline: none;
    cursor: pointer;
    border-radius: 20px;
    overflow: hidden;
    text-wrap: nowrap;
}

#create-subtask{
    background: var(--base-btn-color);
    overflow: hidden;
    margin-left: auto;
    border: none;
    color: var(--text-color);
    font-size: 16px;
    font-family: var(--base-font-family);
    font-weight: 400;
    padding: 10px 15px 10px 15px;
    outline: none;
    cursor: pointer;
    border-radius: 20px;
    text-wrap: nowrap;
}

#image-performer{
    height: 31px;
    width: 31px;
    display: flex;
    border: none;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
}

#not-image-comment-performer{
    width: 30px;
    height: 30px;
}

#comment-username{
    display: flex;
    font-size: 12px;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    align-items: center;
}

#div-with-mini-card-related{
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
    justify-content: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    max-height: 25%;
    gap: 10px;
}

#div-task-file{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.div-right{
    display: flex;
    width: 35px;
    height: 35px;
    margin-top: 10px;
    margin-left: 10px;
    order: 1;
    align-items: center;
    flex-direction: column;
}

.div-left{
    display: flex;
    width: 35px;
    height: 35px;
    margin-top: 14px;
    margin-right: 10px;
    align-items: center;
    flex-direction: column;
}

#div-not-column{
    display: flex;
    margin-bottom: 10px;
}

#send-comment{
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    resize: none;
    margin-right: 20px;
    white-space: nowrap;
    color: var(--base-text-color);
    font-size: 30px;
    scrollbar-width: none;
    cursor: pointer;
}

.Overlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--overlay-info-color);
    z-index: 2;
}

#create-label{
    display: flex;
    margin-left: 60px;
    margin-top: 20px;
    width: 120px;
    padding: 5px;
    height: 40px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 18px;
    color: var(--text-color);
    padding: 15px;
    border: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    font-family: var(--base-font-family);
    background: var(--base-btn-color);
    transition: background-color 0.3s ease, transform 0.1s ease, box-shadow 0.3s ease;
}

#mini-card-detail-task{
    display: flex;
    flex-direction: column;
    border: none;
    width: 180px;
    height: 100px;
    border-radius: 10px;
    text-align: left;
    text-wrap: wrap;
    background: var(--mini-task-card-color);
    padding: 10px;
    color: var(--base-text-color);
    padding-bottom: 5px;
    cursor: pointer;
}

#div-mini-card-detail-task{
    display: flex;
    flex-direction: column;
    border: none;
    width: 170px;
    height: 90px;
    border-radius: 10px;
    text-align: left;
    text-wrap: wrap;
    background: var(--mini-task-card-color);
    padding: 10px;
    color: var(--base-text-color);
    padding-bottom: 5px;
    cursor: pointer;
}

#div-calendar{
    display: flex;
    min-height: 50px;
    border-radius: 20px;
    min-width: 240px;
    max-width: 250px;
    box-shadow: none;
    margin-top: 30px;
    flex-direction: column;
}

/* design type file extension *//* design type file extension *//* design type file extension *//* design type file extension */

#imagefile{
    min-width: 150px;
    min-height: 100px;
    max-width: 150px;
    max-height: 100px;
    border: none;
    border-radius: 10px;
    margin-right: auto;
    margin-left: 10px;
}

#doc-extension-file{
    display: flex;
    flex-direction: column;
    min-width: 150px;
    min-height: 100px;
    max-width: 150px;
    max-height: 100px;
    border: none;
    background: rgba(18, 106, 206, 0.692);
    border-radius: 6px;
    margin-right: auto;
    margin-left: 10px;
    overflow: hidden;
}

#doc-type-extension-div{
    max-width: 50px;
    max-height: 20px;
    padding: 2px;
    border: none;
    background: rgb(0, 140, 255);
    color: var(--text-color);
    font-family: var(--base-font-family);
    font-weight: 600;
    border-radius: 4px;
    margin-left: 10px;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
}

#file-name-for-user{
    display: block;
    color: var(--text-color);
    max-width: 120px;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    align-self: center;
    text-wrap: wrap;
    overflow-wrap: break-word;
    margin-left: 10px;
    margin-right: 10px;
    word-wrap: break-all;
    margin-top: 5px;
}

#xls-extension-file{
    display: flex;
    flex-direction: column;
    min-width: 150px;
    min-height: 100px;
    max-width: 150px;
    max-height: 100px;
    border: none;
    background: rgba(37, 116, 27, 0.692);
    border-radius: 6px;
    margin-right: auto;
    margin-left: 10px;
    overflow: hidden;
}

#xls-type-extension-div{
    max-width: 50px;
    max-height: 20px;
    padding: 2px;
    border: none;
    background: rgba(60, 197, 33, 0.877);
    color: var(--text-color);
    font-family: var(--base-font-family);
    font-weight: 600;
    border-radius: 4px;
    margin-left: 10px;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
}

#ppt-extension-file{
    display: flex;
    flex-direction: column;
    min-width: 150px;
    min-height: 100px;
    max-width: 150px;
    max-height: 100px;
    border: none;
    background: rgba(201, 123, 6, 0.692);
    border-radius: 6px;
    margin-right: auto;
    margin-left: 10px;
    overflow: hidden;
}

#ppt-type-extension-div{
    max-width: 50px;
    max-height: 20px;
    padding: 2px;
    border: none;
    background: rgba(255, 174, 0, 0.877);
    color: var(--text-color);
    font-family: var(--base-font-family);
    font-weight: 600;
    border-radius: 4px;
    margin-left: 10px;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
}

#pdf-extension-file{
    display: flex;
    flex-direction: column;
    min-width: 150px;
    min-height: 100px;
    max-width: 150px;
    max-height: 100px;
    border: none;
    background: rgba(201, 6, 6, 0.5);
    border-radius: 6px;
    margin-right: auto;
    margin-left: 10px;
    overflow: hidden;
}

#pdf-type-extension-div{
    max-width: 50px;
    max-height: 20px;
    padding: 2px;
    border: none;
    background: rgba(196, 15, 15, 0.877);
    color: var(--text-color);
    font-family: var(--base-font-family);
    font-weight: 600;
    border-radius: 4px;
    margin-left: 10px;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
}

#txt-extension-file{
    display: flex;
    flex-direction: column;
    min-width: 150px;
    min-height: 100px;
    max-width: 150px;
    max-height: 100px;
    border: none;
    background: rgba(136, 136, 136, 0.5);
    border-radius: 6px;
    margin-right: auto;
    margin-left: 10px;
    overflow: hidden;
}

#txt-type-extension-div{
    max-width: 50px;
    max-height: 20px;
    padding: 2px;
    border: none;
    background: rgba(94, 94, 94, 0.877);
    color: var(--text-color);
    font-family: var(--base-font-family);
    font-weight: 600;
    border-radius: 4px;
    margin-left: 10px;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
}

#zip-extension-file{
    display: flex;
    flex-direction: column;
    min-width: 150px;
    min-height: 100px;
    max-width: 150px;
    max-height: 100px;
    border: none;
    background: rgba(31, 31, 31, 0.5);
    border-radius: 6px;
    margin-right: auto;
    margin-left: 10px;
    overflow: hidden;
}

#zip-type-extension-div{
    max-width: 50px;
    max-height: 20px;
    padding: 2px;
    border: none;
    background: rgba(36, 36, 36, 0.877);
    color: var(--text-color);
    font-family: var(--base-font-family);
    font-weight: 600;
    border-radius: 4px;
    margin-left: 10px;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
}

/* design type file extension *//* design type file extension *//* design type file extension *//* design type file extension */

#div-delete-get-file{
    border: none;
    width: 150px;
    margin-top: 75px;
    margin-left: 10px;
    display: flex;
    position: absolute;
    background: none;
    z-index: 10;
}

#div-for-files{
    position: relative;
    display: flex;
    margin-bottom: 10px;
    cursor: pointer;
}

#get-file{
    margin-left: auto;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.7);
    background-image: url('/public/download-file.svg');
    width: 25px;
    height: 25px;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: center;
}

#delete-label-btn{
    background: none;
    border: none;
    color: var(--delete-btn-color);
    border-radius: 5px;
    font-size: 19px;
    font-family: var(--base-font-family);
    cursor: pointer;
    margin-left: auto;
}

#date-time-label-in-comment{
    font-size: 9px;
    font-family: var(--base-font-family);
    color: var(--base-text-color);
    margin-top: auto;
    margin-left: auto;
    padding: 0px 4px 4px 4px;
}

#select-board {
    display: flex;
    flex-wrap: wrap;
    border: none;
    padding: 15px;
    white-space: normal;
    overflow-wrap: break-word;
    cursor: pointer;
    word-break: break-word;
    background: var(--btn-data-element-color);
    transition: background-color 0.3s ease, transform 0.1s ease, box-shadow 0.3s ease;
    max-width: 220px;
    font-size: 16px;
    font-family: var(--base-font-family);
    color: var(--base-text-color);
    border-radius: 20px;
    text-align: left;
}

#select-board:hover{
    background: var(--btn-data-element-hover);
}

#scroll-Y-div{
    display: flex;
    width: 200px;
    gap: 10px;
    padding: 12px;
    max-height: 400px;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0) rgba(0, 0, 0, 0);
}

#open-modal-delete-label{
    height: 45px;
    padding: 10px 15px 10px 15px;
    border: none;
    border-radius: 30px;
    margin-top: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--text-color);
    font-family: var(--base-font-family);
    font-size: 19px;
    font-weight: 600;
    background: var(--delete-btn-color)
}

#link-to-file{
    border: none;
    border-radius: 5px;
    background: #e77c7c80;
    color: var(--base-text-color);
    font-size: 13px;
    padding: 5px;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 5px;
}

.ck-editor__editable {
    padding: 0px 0px 0px 5px !important;
}

.cke-checkbox {
    display: inline-block;
    margin-right: 5px;
}

#search-input-subtasks{
    display: flex;
    max-width: 510px;
    padding: 15px;
    border: var(--base-border-input);
    border-radius: 10px;
    background: var(--base-bg-color-input);
    transition: background-color 0.3s ease, transform 0.1s ease;
    justify-content: left;
    margin-top: 15px;
    align-items: center;
    text-align: left;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 18px;
    font-weight: 500;
    line-height: 10px;
    outline: none;
}

#search-input-subtasks::placeholder{
    color: var(--base-placeholder-input)
}

#div-with-subtasks-and-btn{
    max-width: 525px;
    min-height: 50px;
    padding: 8px;
    border-radius: 10px; 
    display: flex;
    background: var(--base-task-color);
    color: var(--base-text-color);
    margin-bottom: 10px;
}

#check-is-subtasks{
    display: flex;
    justify-content: center;
    min-width: 20px;
    min-height: 20px;
    border: 1px solid white;
}

#open-this-tasks{
    display: flex;
    border: none;
    font-size: 17px;			
    color: var(--base-text-color);;
    background: none;
    font-family: var(--base-font-family);
    cursor: pointer;
    text-wrap: wrap;
    word-wrap: break-word;
    word-break: break-all;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 5px;
    text-align: left;
}

#scroll-subtask{
    max-height: 420px;
    margin-top: 10px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0) rgba(0, 0, 0, 0);
}

#modal-label-answercomment{
    display: flex;
    color: var(--base-text-color);
    margin-top: 10px;
    font-family: var(--base-font-family);
    font-size: 18px;
    font-weight: 500;
    text-align: left;
}

#answer-comment{
    display: flex;
    border-radius: 20px;
    word-break: break-all;
    border: none;
    margin-bottom: 10px;
    padding: 10px;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 16px;
    background: rgba(34, 35, 43, 0.6);
}

#text-answer-comment{
    display: flex;
    border-radius: 10px;
    border: none;
    background: var(--base-bg-color-input);
    font-size: 16px;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    margin-bottom: 15px;
    padding: 10px;
    width: 600px;
    outline: none;
    height: fit-content; 
}

#hint-with-all-users{
    display: flex;
    border: none;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    margin-left: 10px;
    width: 360px;
    max-height: 35px;
    overflow-x: auto;
    background: var(--base-hint-text-color);
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0) rgba(0, 0, 0, 0);
}

#hint-with-users{
    display: flex;
    justify-items: center;
    align-content: center;
    margin-right: 10px;
    height: 45px;
    align-items: center;
    justify-content: center;
}

#select-user{
    border-radius: 5px;
    background: none;
    margin-left: 5px;
    align-content: center;
    font-family: var(--base-font-family);
    font-size: 15px;
    color: var(--base-text-color);
    cursor: pointer;
}

#delete-file-task{
    margin-left: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.7);
    background-image: url('/public/delete-file.svg');
    width: 25px;
    height: 25px;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center;
}

@keyframes slideInRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}