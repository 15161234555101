.priority-low {
    background-color: rgb(100, 120, 214) !important;
}

.priority-medium {
    background-color: rgb(220, 136, 0) !important;
}

.priority-high {
    background-color: rgb(175, 52, 52) !important;
}

#gantt-change-calendar-zoom {
    display: flex;
    margin-bottom: 10px;
    margin-left: 358px;
    max-width: fit-content;
    background: var(--base-element-color);
    padding: 9px;
    border-radius: 10px;
}

#gantt-set-days-btn {
    border-radius: 10px;
    border: none;
    font-weight: 700;
    padding: 8px 10px 8px 10px;
    background: var(--base-btn-color);
    font-size: 16px;
    letter-spacing: -0.5px;
    font-family: var(--base-font-family);
    cursor: pointer;
    color: var(--gantt-taxt-color);
}

#gantt-set-weeks-btn {
    border-radius: 10px;
    border: none;
    font-weight: 700;
    padding: 8px 10px 8px 10px;
    margin-left: 10px;
    background: var(--base-btn-color);
    font-size: 16px;
    letter-spacing: -0.5px;
    font-family: var(--base-font-family);
    cursor: pointer;
    color: var(--gantt-taxt-color);
}

#gantt-set-months-btn {
    border-radius: 10px;
    border: none;
    font-weight: 700;
    padding: 8px 10px 8px 10px;
    margin-left: 10px;
    background: var(--base-btn-color);
    font-size: 16px;
    letter-spacing: -0.5px;
    font-family: var(--base-font-family);
    cursor: pointer;
    color: var(--gantt-taxt-color);
}

.gantt_container {
    border-radius: 20px;
    width: 98%;
    height: 88vh;
}

.gantt_row{
    cursor: pointer;
}

.gantt_container ::-webkit-scrollbar {
    width: 8px;
}

.gantt_container ::-webkit-scrollbar-thumb {
    background: rgb(44, 45, 55);
    border-radius: 4px;
}

:root {
    --dhx-gantt-theme: terrace;
    --dhx-gantt-font-family: var(--base-font-family) !important;
    --dhx-gantt-font-size: 16px !important;

    /* Базовые цвета */
    --dhx-gantt-base-colors-white: var(--dhx-custom-gantt-base-colors-white) !important; 
    --dhx-gantt-base-colors-select: var(--dhx-custom-gantt-base-colors-select) !important; /* Темный оттенок */
    --dhx-gantt-base-colors-hover-color: var(--dhx-custom-gantt-base-colors-hover-color) !important; /* Темный оттенок при наведении */
    --dhx-gantt-base-colors-border-light: var(--dhx-custom-gantt-base-colors-border-light) !important; /* Темная граница */
    --dhx-gantt-base-colors-border: var(--dhx-custom-gantt-base-colors-border) !important; /* Еще темнее граница */

    --dhx-gantt-base-colors-icons: var(--dhx-custom-gantt-base-colors-icons) !important; /* Иконки */
    --dhx-gantt-base-colors-disabled: var(--dhx-custom-gantt-base-colors-disabled) !important; /* Отключенные элементы */
    --dhx-gantt-base-colors-text-light: var(--dhx-custom-gantt-base-colors-text-light) !important; /* Светлый текст */
    --dhx-gantt-base-colors-text-base: var(--dhx-custom-gantt-base-colors-text-base) !important; /* Основной текст (белый) */
    --dhx-gantt-base-colors-text-on-fill: var(--dhx-custom-gantt-base-colors-text-on-fill) !important; /* Текст на заливке */
    --dhx-gantt-base-colors-background: var(--dhx-custom-gantt-base-colors-background) !important; /* Темный фон */
    --dhx-gantt-base-colors-background-alt: var(--dhx-custom-gantt-base-colors-background-alt) !important; /* Альтернативный темный фон */

    /* Цвета для акцентов */
    --dhx-gantt-base-colors-primary: #537CFA !important; /* Синий */
    --dhx-gantt-base-colors-warning: #FAB936 !important; /* Желтый */
    --dhx-gantt-base-colors-error: #E3334E !important; /* Красный */
    --dhx-gantt-base-colors-success: #1BC297 !important; /* Зеленый */

    /* Контейнер */
    --dhx-gantt-container-background: var(--dhx-gantt-base-colors-background); /* Темный фон */
    --dhx-gantt-container-color: var(--dhx-gantt-base-colors-text-base); /* Белый текст */
    --dhx-gantt-offtime-background: var(--dhx-gantt-base-colors-background-alt); /* Альтернативный фон */

    /* Шкала времени */
    --dhx-gantt-scale-background: var(--dhx-gantt-container-background); /* Фон шкалы */
    --dhx-gantt-scale-border-vertical: 1px solid var(--dhx-gantt-base-colors-border-light); /* Вертикальные линии */
    --dhx-gantt-scale-border-horizontal: 1px solid var(--dhx-gantt-base-colors-border); /* Горизонтальные линии */
    --dhx-gantt-scale-color: var(--dhx-gantt-base-colors-text-light); /* Цвет текста шкалы */

    /* Таблица */
    --dhx-gantt-grid-body-background: transparent; /* Прозрачный фон */
    --dhx-gantt-grid-scale-background: var(--dhx-gantt-scale-background); /* Фон заголовков таблицы */
    --dhx-gantt-grid-scale-color: var(--dhx-gantt-scale-color); /* Цвет текста заголовков */
    --dhx-gantt-grid-scale-border-vertical: var(--dhx-gantt-scale-border-vertical); /* Линии между столбцами */

    /* Таймлайн */
    --dhx-gantt-timeline-scale-background: var(--dhx-gantt-scale-background); /* Фон шкалы таймлайна */
    --dhx-gantt-timeline-scale-color: var(--dhx-gantt-scale-color); /* Цвет текста шкалы */
    --dhx-gantt-timeline-scale-border-vertical: var(--dhx-gantt-scale-border-vertical); /* Линии между ячейками */

    /* Задачи */
    --dhx-gantt-task-blue: linear-gradient(180deg, #527CFF 0%, #9751FC 100%);
    --dhx-gantt-task-green: linear-gradient(180deg, #12D979 0%, #1ECDEB 100%);
    --dhx-gantt-task-violet: linear-gradient(180deg, #D071EF 0%, #EE71D5 100%);
    --dhx-gantt-task-yellow: linear-gradient(180deg, #FFB725 0%, #FFBB25 31.25%, #FAEA27 100%);

    /* --dhx-gantt-task-background: var(--dhx-gantt-task-blue); Цвет задач */
    --dhx-gantt-task-border: none; /* Без границ */
    --dhx-gantt-task-color: var(--dhx-gantt-base-colors-text-on-fill); /* Текст задач */
    --dhx-gantt-project-color: var(--dhx-gantt-task-color); /* Цвет проектов */
    --dhx-gantt-task-line-text: var(--dhx-gantt-container-color); /* Текст задач */

    --dhx-gantt-task-row-border: 1px solid var(--dhx-gantt-base-colors-border); /* Линии между строками */
    --dhx-gantt-task-row-background: var(--dhx-gantt-container-background); /* Фон строк */
    --dhx-gantt-task-row-background--odd: var(--dhx-gantt-container-background); /* Фон нечетных строк */

    --dhx-gantt-project-background: var(--dhx-gantt-task-green); /* Цвет проектов */
    --dhx-gantt-milestone-background: var(--dhx-gantt-task-violet); /* Цвет вех */

    --dhx-gantt-task-marker-color: var(--dhx-gantt-task-background); /* Маркеры задач */

    /* Всплывающие окна */
    --dhx-gantt-popup-background: var(--dhx-gantt-container-background); /* Фон всплывающих окон */
    --dhx-gantt-popup-color: var(--dhx-gantt-container-color); /* Текст всплывающих окон */

    /* Подсказки */
    --dhx-gantt-tooltip-background: var(--dhx-gantt-base-colors-text-base); /* Фон подсказок */
    --dhx-gantt-tooltip-color: var(--dhx-gantt-container-background); /* Текст подсказок */

    /* Связи */
    --dhx-gantt-link-background: var(--dhx-gantt-base-colors-icons); /* Цвет связей */
    --dhx-gantt-link-background-hover: var(--dhx-gantt-base-colors-icons-hover); /* Цвет связей при наведении */
    --dhx-gantt-link-critical-background: var(--dhx-gantt-base-colors-error); /* Цвет критических связей */
}