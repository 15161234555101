#div-with-main-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 35px;
}

#div-my-tasks-and-statistics{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 35px;

}

#div-news-and-notification{
    display: flex;
    flex-direction: row;
    gap: 35px;
    margin-top: 35px;
    justify-content: center;

}

.Overlayforcreategroup{
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--overlay-info-color);
    z-index: 2;
    height: 100vh;
    width: 100%;
    justify-content: center;
}

#btn-modal-close{
    cursor: pointer;
}

#div-with-bottom-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    max-height: 80vh;
    width: fit-content;
    border-radius: 20px;
    min-width: 170px;
    padding: 20px;
    background: var(--base-element-color);
}

#label-title-card {
    display: flex;
    font-size: 25px;
    font-family: var(--base-font-family);
    font-weight: 600;
    justify-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
    color: var(--base-text-color);;
}

#label-title-card-alert {
    font-size: 23px;
    font-family: var(--base-font-family);
    font-weight: 600;
    text-align: center;
    color: var(--base-text-color);
}

#see-task-btn {
    display: flex;
    height: 30px;
    min-width: 220px;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    font-weight: 600;
    align-items: center;
    border: none;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    background: none;
    font-size: 18px;
    cursor: pointer
}

#div-see-task-btn {
    display: flex;
}

#div-with-my-tasks {
    display: flex;
    flex-direction: column;
    height: 465px;
    max-height: 530px;
    width: 700px;
    padding-bottom: 25px;
    padding-top: 25px;
    padding-left: 30px;
    padding-right: 30px;
    background: var(--base-element-color);
    border-radius: 20px;
    overflow-y: hidden;
}

#toggle-open-tasks {
    margin-left: auto;
    color: var(--base-text-color);
}


#user-static-div {
    font-size: 18px;
    color: var(--base-text-color);
    width: fit-content;
    font-family: var(--base-font-family);
}

#div-with-news {
    display: flex;
    height: fit-content;
    flex-direction: column;
    width: 700px;
    padding: 20px;
    border-radius: 20px;
    background: var(--base-element-color);

}

#mini-card-task {
    display: flex;
    flex-direction: column;
    border: none;
    width: 200px;
    min-height: 85px;
    border-radius: 10px;
    text-align: left;
    text-wrap: wrap;
    background: var(--mini-task-card-color);
    padding: 10px;
    color: var(--base-text-color);
    padding-bottom: 5px;
    cursor: pointer;
}

#mini-card-task-deadline{
    font-size: 12px;
    font-family: var(--base-font-family);
    margin-right: auto;
    margin-top: auto;
    font-weight: 700;
    cursor: pointer;
    color: var(--base-text-color);
}

#mini-card-task-time-at-status{
    font-size: 12px;
    font-family: var(--base-font-family);
    margin-top: auto;
    font-weight: 700;
    cursor: pointer;
    color: var(--time-task-status-color);
}

#detail-statistic-username{
    color: var(--base-text-color);;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
    max-width: 180px;
    font-family: var(--base-font-family);
    align-self: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

#mini-card-div-with-deadline-and-priority {
    display: flex;
    width: 100%;
    cursor: pointer;
    margin-right: auto;
    margin-top: auto;
}

#div-with-mini-card-bottom {
    display: flex;
    overflow-y: scroll;
    margin-top: 10px;
    flex-wrap: wrap;
    justify-content: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    gap: 10px;
    max-width: fit-content;
}

#mini-card-task-title{
    max-width: 180px;
    text-align: left;
    margin-top: -4px;
    text-wrap: wrap;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--base-text-color);
    font-size: larger;
    font-family: var(--base-font-family);
    font-weight: 700;
    cursor: pointer;
}

#div-with-notification {
    display: flex;
    height: fit-content;
    flex-direction: column;
    width: 480px;
    margin-left: auto;
    padding: 20px;
    border-radius: 20px;
    background: var(--base-element-color);
    overflow-x: hidden;
}

#div-with-label-statistics-user{
    max-height: fit-content;
    display: flex;
    min-height: 235px;
    height: fit-content;
    width: 450px;
    flex-direction: column;
    padding-top: 25px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 20px;
    border-radius: 20px;
    background: var(--base-element-color);
    overflow-x: hidden;
}

#notification-icon {
    width: 20px;
    height: 20px;
    margin-left: auto;
}

#div-with-mini-card {
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
    margin-top: 10px;
    overflow-y: scroll;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    gap: 10px;
    max-height: 400px;
}

#div-with-mini-card-detail-user {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    overflow-y: scroll;
    gap: 10px;
    overflow-x: hidden;
    max-height: 27%;
    min-height: 27%;
}

#div-with-mini-card-detail-user::-webkit-scrollbar {
    width: 3px;
}

#div-with-mini-card-detail-user::-webkit-scrollbar-track {
    background: white;
}

#div-with-mini-card-detail-user::-webkit-scrollbar-thumb {
    background-color: rgba(49, 49, 134, 0.479);
    border-radius: 4px;
}

#div-with-mini-card::-webkit-scrollbar {
    width: 3px;
}

#div-with-mini-card::-webkit-scrollbar-track {
    background: white;
}

#div-with-mini-card::-webkit-scrollbar-thumb {
    background-color: rgba(49, 49, 134, 0.479);
    border-radius: 4px;
}

#div-with-list-user::-webkit-scrollbar {
    width: 3px;
}

#div-with-list-user::-webkit-scrollbar-track {
    background: white;
}

#div-with-list-user::-webkit-scrollbar-thumb {
    background-color: rgba(49, 49, 134, 0.479);
    border-radius: 4px;
}

#div-with-list-user {
    display: flex;
    margin-left: 5px;
    max-height: 260px;
    flex-direction: column;
    justify-content: left;
    overflow-y: scroll;
    padding-right: 5px;
    gap: 5px;
}

#div-with-label-and-icon {
    display: flex;
    align-self: center;
    margin-bottom: 10px;
}

#div-with-username-and-count-task {
    display: flex;
    background-color: var(--base-task-color);
    padding: 7px;
    border-radius: 6px;
    color: var(--base-text-color);
}

#count-task {
    color: var(--base-text-color);
    margin-left: auto;
    font-size: 18px;
    font-family: var(--base-font-family);
}

#statistics-username {
    color: var(--base-text-color);
    font-size: 18px;
    max-width: 350px;
    font-family: var(--base-font-family);
}

#div-for-detail-statistic-user{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 55px;
    height: 87%;
    padding-top: 20px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 20px;
    border-radius: 20px;
    background-color: var(--base-element-color);
    overflow-y: hidden;
}

#div-with-detail-user-selected-task{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    border-radius: 20px;
    background-color: var(--base-element-color);
    overflow-y: hidden;
    padding: 15px;
}

#close-period-statistics{
    display: flex;
    width: 30px;
    border: none;
    background: rgba(223, 64, 64, 0.219);
    color: var(--delete-btn-color);
    border-radius: 8px;
    border: none;
    font-weight: 600;
    font-family: var(--base-font-family);
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;
}

#div-with-table-user-period-statistics{
    margin-left: 30px;
    background-color: var(--base-table-color);
    overflow-y: scroll;
    border-radius: 12px;
}

#close-modal-calendar-btn{
    display: flex;
    margin-left: auto;
    background: none;
    color: var(--delete-btn-color);
    border: none;
    font-size: 18px;
    font-family: var(--base-font-family);
    cursor: pointer;
}

#table-user-period-statistics {
    width: 100%;
    border-collapse: collapse;
    border-radius: 10px;
}

#table-user-period-statistics thead {
    background-color: transparent;
    border-radius: 10px;
}

#user-name-left-period-statistic-table{
    padding: 8px 11px;
    text-align: center;
    border-radius: 8px;
    color: var(--base-text-color);
}

#other-period-statistic-td-th{
    padding: 12px 15px;
    text-align: center;
    color: var(--base-text-color);
}

#table-user-period-statistics tr:hover {
    background-color: #606097;
    border-radius: 10px;
}

#table-user-period-statistics button {
    font-family: var(--base-font-family);
    background-color: var(--base-table-btn-color);
    color: var(--base-text-color);
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

#table-user-period-statistics button:hover {
    background-color: var(--base-table-btn-hover-color);
}

#label-detail-statistic-user{
    font-size: 22px;
    display: flex;
    font-family: var(--base-font-family);
    font-weight: 600;
    color: var(--base-text-color);;
    margin-right: auto;
}

#filter-for-detail-user-task{
    display: flex;
    margin-bottom: 10px;
    margin-top: 15px;
    background-color: var(--base-element-color);
}

#open-calendar-detail-user-task{
    display: flex;
    border: none;
    font-family: var(--base-font-family);
    font-weight: 600;
    background: var(--base-btn-color);
    color: var(--text-color);
    border-radius: 10px;
    border: none;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
    margin-left: 30px;
    cursor: pointer;
}

.ModalDetailUserStatistic{
    display: flex;
    align-items: center;
    width: 80%;
}

#div-with-user-task{
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    min-width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
}

#div-with-detail-user-task{
    display: flex;
    width: 100%;
    height: 100%;
    overflow-x: scroll;
}

#div-with-detail-user-task::-webkit-scrollbar {
    max-width: 10px;
}

#div-with-detail-user-task::-webkit-scrollbar-track {
    background: white;
}

#div-with-detail-user-task::-webkit-scrollbar-thumb {
    background-color: rgb(47, 47, 61);
    border-radius: 4px;
}

#button-open-detail-task-user{
    border-radius: 10px;
    border: none;
    font-weight: 700;
    padding: 10px;
    margin-top: 20px;
    background: var(--base-btn-color);
    font-size: 16px;
    font-family: var(--base-font-family);
    cursor: pointer;
    color: var(--text-color);
    transition: background-color 0.3s ease, transform 0.1s ease;
}

#button-open-detail-task-user:hover{
    background-color: var(--base-btn-hover-color);
    color: var(--text-color);
    border: none;
    box-shadow: 0px 4px 14px 1px var(--base-btn-hover-color);
}
