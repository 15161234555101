.login-page {
  display: flex;
  width: 100%;
  min-height: 90vh;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.login-form {
  width: 400px;
  padding: 40px;
  box-sizing: border-box;
	border-radius: 20px;	
  box-shadow: 0px 10px 25px 0px rgba(39, 33, 44, 0.733);
  background: var(--base-element-color);
  z-index: 1;
}

.login-form h1 {
  font-family: var(--base-font-family);
  margin-top: 0;
  margin-bottom: 30px;
  color: var(--base-text-color);
  text-align: center;
}

#iframe{
    border-radius: 10px !important;
}

#yandexAuthButtonContainer{
  width: 320px !important;
  margin-top: 10px !important;
}

#username-txt{
  width: 100%;
  padding: 15px;
  font-size: 16px;
  color: var(--base-text-color);
  margin-bottom: 10px;
  font-family: var(--base-font-family);
  box-sizing: border-box;
	border-radius: 30px;
  border: var(--base-border-input);
  outline: none;
  background: var(--base-bg-color-input);
}

#other-auth-button{
  display: flex;
}

input[type="password"] {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  color: var(--base-text-color);
  margin-bottom: 10px;
  font-family: var(--base-font-family);
  box-sizing: border-box;
	border-radius: 30px;
  border: var(--base-border-input);
  outline: none;
  background: var(--base-bg-color-input);
}

input[type="password"]::placeholder{
  color: var(--base-placeholder-input);
}

#password{
  width: 100%;
  padding: 15px;
  font-size: 16px;
  color: var(--base-text-color);
  margin-bottom: 10px;
  font-family: var(--base-font-family);
	border-radius: 30px;
  border: var(--base-border-input);
  outline: none;
  background: var(--base-bg-color-input);
}

#tgbutton{
  display: flex;
  background: none !important;
}

#change-password-btn{
  display: flex;
  padding: 5px;
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  color: var(--base-text-color);
  border: none;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  margin-top: 10px;
  padding: 10px 15px 10px 15px;
  border-radius: 20px;
  font-family: var(--base-font-family);
  background: var(--base-btn-color);
  transition: background-color 0.3s ease, transform 0.1s ease, box-shadow 0.3s ease;
}

#change-password-btn:hover{
  background: var(--base-btn-hover-color);
  color: var(--base-text-color);
  box-shadow: var(--base-btn-hover-color) 0.5px 2px 20px;
}

#div-tg-btn{
  display: flex;
  background-color: var(--telegram-btn-color);
  border-radius: 10px;
  border: none;
  color: var(--base-text-color);
  width: 320px;
  height: 40px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

#login{
  width: 320px;
  height: 40px;
  padding: 15px;
  border: 0px;
  border-radius: 10px;
  background: var(--base-btn-color);
  cursor: pointer;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--base-text-color);
  font-family: var(--base-font-family);
  font-size: 16px;
  font-weight: 700;
  transition: box-shadow 0.3s ease, transform 0.1s ease;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

#login:hover {
  background: var(--base-btn-hover-color);
  color: var(--base-text-color);
  box-shadow: var(--base-btn-hover-color) 0.5px 2px 20px;
}