.detail-board-page{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    height: 95vh;
}

#divwithgroup{
    display: flex;
    margin-top: 65px;
    overflow-y: hidden;
    overflow-x: scroll;
}

#divwithgroup::-webkit-scrollbar {
    width: 4px;
    height: 6px;
    border-radius: 10px;
}
#divwithgroup::-webkit-scrollbar-track {
    background: rgba(34, 35, 43, 0.6);
}
#divwithgroup::-webkit-scrollbar-thumb {
    background: #7F8094;
}

#task-max-performer-in-status{
    display: flex;
    outline: none;
    max-height: 80px;
    background: var(--base-bg-color-input);
    border: none;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 28px;
    font-weight: 600;
    max-width: 220px;
    overflow: hidden;
    border: var(--base-border-input);
    border-radius: 30px;
    padding-left: 20px
}

#div-board-filter-sort {
    display: flex;
    position: fixed;
    width: 50px;
}

@media (max-width: 499px) {
    #div-board-filter-sort{
        margin-left: 80px;
    }
}

#task-max-performer-in-status::placeholder{
    color: var(--base-placeholder-input);
}

#group{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 85vh;
    border-radius: 12px;
    background: var(--base-element-color);
    height: fit-content;
    padding: 10px;
}

#titlelabel{
    display: flex;
    border: none;
    background: var(--base-bg-color-input);
    cursor: text;
    margin-right: auto;
    transition: background-color 0.3s ease, transform 0.1s ease;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 22px;
    font-weight: 600;
    overflow: hidden;
    width: 300px;
    outline: none;
    white-space: wrap;
}

#icon-right-for-task{
    display: flex;
    flex-direction: column;
    margin-left: auto;
    min-width: max-content;
    flex-wrap: nowrap;
}

#overdue-deadline{
    display: flex;
    margin-left: 10px;
}

#icon-for-right-task{
    display: flex;
    margin-right: 3px;
    max-width: 20px;
    max-height: 20px;
}

#task-file-length{
    display: flex;
    align-items: center;
    margin-left: auto;
    color: var(--base-text-color);
}

#drop-for-group-div{
    min-width: 20px;
    height: 80vh;
    display: flex;
    transition: width 0.3s ease, box-shadow 0.3s ease;
    border-radius: 4px;
    margin-top: 20px;
    z-index: 2;
}

#div-for-animated-group-draggable {
    display: flex;
    height: 90vh;
}

#divwiththegrouptasks{
    display: flex;
    position: relative;
    padding-bottom: 5px;
    min-height: 130px;
    min-width: 410px;
    flex-direction: column;
    max-width: 425px;
    overflow-y: scroll;
    align-items: center;
}

#divwiththegrouptasks::-webkit-scrollbar {
    max-width: 5px;
}

#divwiththegrouptasks::-webkit-scrollbar-track {
    background: none;
}

#divwiththegrouptasks::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 5px;
}

#detailtask{
    padding: 10px;
    display: flex;
    width: 400px;
    height: fit-content;
    justify-content: left;
    border-radius: 8px;
    background: var(--base-task-color);
    font-size: 18px;
    color: var(--base-text-color);
    font-weight: 500;
    cursor: pointer;
    white-space: pre-line;
    word-break: break-word;
    text-align: left;
    flex-direction: column;
    border: none;
    transition: all 0.5s ease-in-out;
}

#divforinfo{
    display: flex;
    flex-direction: column;
    /* pointer-events: none; */
}

#createnewgroup{
    height: 40px;
    padding: 30px;
    border: 0px;
    border-radius: 10px;
    background: var(--base-btn-color);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    transition: box-shadow 0.3s ease, transform 0.1s ease;
    transition: background-color 0.3s ease, transform 0.1s ease;
}

#createnewgroup:hover {
    background: var(--base-btn-hover-color);
    color: var(--base-text-color);
    box-shadow: var(--base-btn-hover-color) 0.5px 2px 20px;
}

#addtask{
    height: 24px;
    width: 24px;
    padding: 15px;
    background: none;
    margin-top: 3px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--base-text-color);
}

#btn-create-new-task-plus{
    width: 24px;
    height: 24px;
}

#detailtask:hover{
    background: var(--base-task-hover-color);
}

#div-for-performer-column-image{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#userphoto{
    width: 30px;
    height: 28.86px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    /* pointer-events: none; */
    border: none;
}

#user-template-photo{
    width: 35px;
    height: 35px;
    pointer-events: none;
}

#div-main-info-task{
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    /* pointer-events: none; */
}

#divforlabel{
    display: flex;
    height: fit-content;
    max-width: 150px;
    min-width: 150px;
    max-height: 50px;
    min-height: 18px;
    margin-left: 10px;
    display: grid;
    justify-content: left;
    align-items: center;
    border-radius: 10px;
    padding: 5px;
    pointer-events: none;
}

#divforlabeltitle{
    display: flex;
    font-size: 12px;
    border-radius: 6px;
    font-family: var(--base-font-family);
    pointer-events: none;
}

#div-with-title-task{
    display: flex;
    width: 370px;
    font-family: var(--base-font-family);
    padding-bottom: 10px;
    padding-left: 5px;
    text-wrap: wrap;
    word-wrap: break-word;
}

#circle{
    width: 12px;
    height: 12px;
    border-radius: 10px;
    display: inline-block;
    align-items: center;
    margin-top: 3px;
    pointer-events: none;
}

#label-name-color{
    display: flex;
    font-size: 12px;
    padding-left: 6px;
    border-radius: 5px;
    pointer-events: none;
}

.no-resize{
    resize: none;
}
  
input[type="radio"] {
    display: none;
}

input[type="checkbox"] {
    display: none;
}

#drop-for-task{
    width: 400px;
    height: 10px;
    background-color: transparent;
    transition: all 0.3s ease;
}

#my-tooltip{
    background: var(--base-tooltip-color);
    color: var(--text-color);
    font-family: var(--base-font-family);
    border-radius: 10px;
    font-weight: 800;
    z-index: 10;
}

#groupinput{
    display: flex;
    width: 98%;
    height: fit-content;
    cursor: pointer;
}

#divforbuttuoncloseandbuttonopen{
    display: flex;
    flex-direction: column;
}

#openaccessdeletegroup{
    display: flex;
    border: none;
    margin-top: 7px;
    background: none;
    margin-bottom: auto;
    cursor: pointer;
    font-weight: 100;
    margin-left: 10px;
    font-size: 30px;
    align-self: center;
}

#name-group-input{
    display: flex;
    width: 100%;
    background: var(--base-bg-color-input);
    border: var(--base-border-input);
    border-radius: 20px;
    padding: 0px 15px 0px 15px;
    box-sizing: border-box;
    margin-bottom: 10px;
    height: 40px;
    outline: none;
    font-family: var(--base-font-family);
    font-size: 16px;
    font-weight: 500;
    color: var(--base-text-color);
}

#open-settings-task{
    border-radius: 10px;
    border: none;
    font-weight: 700;
    padding: 8px 10px 8px 10px;
    background: var(--base-btn-color);
    font-size: 16px;
    letter-spacing: -0.5px;
    font-family: var(--base-font-family);
    cursor: pointer;
    color: var(--gantt-taxt-color);
    width: 170px;
}

#post-select-change-for-new-column{
    max-width: 240px;
    padding-left: 10px;
    font-family: var(--base-font-family);
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    border: none;
    border-radius: 30px;
    background-color: var(--base-select-filter-color);
    color: var(--base-text-color);
    cursor: pointer;
}

.close-modal-task{
    position: absolute;
    top: 10px;
    left: -20px;
    width: 25px;
    height: 25px;
    background: var(--task-modal-element-color);
    padding: 10px;
    border-radius: 30px;
    cursor: pointer;
}
  
#access-delete-group{
    display: flex;
    padding: 10px;
    width: 120px;
    border-radius: 30px;
    background: none;
    font-weight: 600;
    color: var(--text-color);
    background-color: var(--delete-btn-color);
    border: none;
    font-size: 18px;
    justify-content: center;
    margin-right: auto;
    align-items: center;
    cursor: pointer;
    margin-top: 15px;
    transition: box-shadow 0.2s ease, transform 0.1s ease;
}

#access-delete-group:hover{
    color: var(--text-color);
    box-shadow: var(--delete-btn-color) 0.5px 2px 20px;
}

#post-select{
    width: 130px;
    background-color: var(--base-select-filter-color);
}

#hint-for-group-settings{
    display: flex;
    font-size: 15px;
    font-weight: 500;
    margin-left: auto;
    max-width: 290px;
    color: var(--base-hint-text-color);
    font-family: var(--base-font-family);
}

#hint-for-length-task-inside-group{
    display: flex;
    font-size: 15px;
    font-weight: 500;
    margin-top: 4px;
    color: var(--base-hint-text-color);
    font-family: 'Inter', sans-serif;
    align-self: center;
}

#sort-calendar-deadline-task-board{
    display: flex;
    width: 35px;
    height: 35px;
    cursor: pointer;
}

#sort-calendar-created-at-task-board{
    display: flex;
    width: 35px;
    height: 35px;
    cursor: pointer;
    margin-left: 8px;
}

#div-with-lite-sort{
    display: flex;
    padding: 9px;
    align-items: center;
    background-color: var(--base-element-color);
    border-radius: 10px;
}

#sort-priority-task-board{
    display: flex;
    width: 35px;
    height: 35px;
    cursor: pointer;
    margin-left: 8px;
}

#open-view-board{
    display: flex;
    width: 33px;
    height: 33px;
    cursor: pointer;
    margin-right: 8px;
    background-color: #ffebd1;
    border-radius: 2.5px;
}

#open-calendar-view{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
    cursor: pointer;
    margin-left: 8px;
    background-color: #ffebd1;
    border-radius: 2.5px;
}

#task-time-inside-group{
    display: flex;
    color: rgb(218, 151, 51);
}