#txt{
    position: absolute;
    top: 45%;
    left: 40%;
}

.main-page{
    display: flex;
    flex-direction: column;
    background: none;
    height: 95vh;
}

#main-page-scroll {
    margin-top: -20px;
    margin-left: -10px;
    padding-top: 20px;
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(auto-fill, minmax(300px, 300px));
    overflow-y: auto;
    gap: 15px;
    padding-left: 30px;
    justify-content: flex-start;
}

@media (width < 760px) {
    #main-page-scroll {
        justify-content: center;
        padding-left: 0px;
    }
}

#divactivebtn{
    display: flex;
    width: 250px;
    flex-direction: column;
    z-index: 1;
    margin-left: 30px;
}
#enter{
    height: 20px
}

.label-selected-organization{
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 20px;
    font-weight: 700;
    align-self: center;
    margin-left: 20px;
}

#notification-this-board{
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 18px;
    font-weight: 700;
    margin-left: 2px;
    margin-right: auto;
}

#hint-for-create-board-slug{
    display: flex;
    font-family: var(--base-font-family);
    max-width: 100%;
    color: var(--base-hint-text-color);
    font-size: 14px;
    margin-left: 3px;
    margin-right: 3px;
    text-align: justify;
    margin-bottom: 10px;
}

#null-tasks{
    width: 100%;
    height: 80vh;
    grid-column: 1 / span 4;
}

.board-input{
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    margin-bottom: 10px;
    font-size: 16px;
    border-radius: 10px;
    background: var(--base-bg-color-input);
    outline: none !important;
    border: var(--base-border-input);
    font-family: var(--base-font-family);
    font-weight: 600 !important;
    color: var(--base-text-color);
}

.board-input::placeholder{
    color: var(--base-placeholder-input);
}

.div-for-btn-header {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    margin-right: 20px;
}

#div-notification-from-board{
    display: flex;
    margin-bottom: 15px;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-weight: 700;
    width: 100%;
}

#nameuser{
    font-weight: 600;
    font-family: var(--base-font-family);
}

button:active {
    transform: translateY(2px)
}

#divforboard{
    position: relative;
    color: var(--base-text-color);
    font-size: 24px;
    width: 300px;
    height: 200px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

#author{
    position: absolute;
    bottom: 8px;
    right: 10px;
    cursor: pointer;
    border: none;
    font-family: var(--base-font-family);
    font-size: 18px;
    background: none;
    color: var(--base-text-color);
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
}

#opencloseaccess{ 
    position: absolute;
    right: 3px;
    top: 5px;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 38px;
    width: 40px;
    height: 40px;
}

#buttonfordiv{
    padding-bottom: 150px;
    padding-top: 10px;
    padding-left: 15px;
    color: var(--base-text-color);
    font-size: 24px;
    width: 300px;
    height: 200px;
    border-radius: 12px;
    border: none;
    background: var(--card-board-color);
    cursor: pointer;
}

#title{
    display: flex;
    font-family: var(--base-font-family);
    max-width: 240px;
    font-weight: 600;
    word-break: break-word;
    text-wrap: wrap;
    text-align: left;
}

#is-private-div{
    display: flex;
    height: 30px;
    margin-top: 10px;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-weight: 700;
    width: 100%;
}

#label-private{
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 18px;
    font-weight: 700;
    margin-left: 2px;
    margin-right: auto;
}

#div-with-user-change-access-board{
    display: flex;
    flex-direction: column;
    overflow: scroll;
    max-height: 30vh;
}

#div-with-user-change-access-board::-webkit-scrollbar {
    max-width: 5px;
}

#div-with-user-change-access-board::-webkit-scrollbar-track {
    background: none;
}

#div-with-user-change-access-board::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 5px;
}

#name-user-board{
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 16px;
    margin-right: auto;
}

#checkbox-add-user-board{
    display: flex;
    margin-top: 5px;
    border-bottom: 1px solid rgb(71, 72, 88);
    margin-bottom: 5px;
    padding-bottom: 5px;
}

#board-form{
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    width: 100%;
}

#show-board-button{
    position: absolute;
    display: flex;
    cursor: pointer;
    width: 30px;
    height: 30px;
    left: 20px;
    bottom: 10px;
}

#hide-board-button{
    position: absolute;
    display: flex;
    cursor: pointer;
    width: 25px;
    height: 25px;
    left: 20px;
    bottom: 10px;
}

#buttonfordiv:hover{
    color: var(--base-text-color);
    transition: background-color 0.3s ease, transform 0.1s ease;
    background: var(--card-board-hover-color);
    box-shadow: 0px 4px 24px 10px var(--card-board-hover-color);
}

#openAccessDelete{
    background: var(--delete-btn-color);
    border: none;
    color: var(--text-color);
    font-size: 19px;
    font-family: var(--base-font-family);
    font-weight: 600;
    padding: 10px 15px 10px 15px;
    outline: none;
    cursor: pointer;
    border-radius: 20px;
    margin-top: 15px;
}

#openAccessDelete:hover{
    background: var(--delete-btn-color);
    color: var(--text-color);
    box-shadow: var(--delete-btn-color) 0.5px 2px 20px;
}