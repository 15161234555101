.choice-organization-page{
    display: flex;
    align-items: center;
    z-index: 5;
    flex-direction: column;
    gap: 10px;
    padding: 10px 20px 10px 20px
}

#button-choice-organization{
    display: flex;
    background: var(--base-btn-color);
    width: 250px;
    border-radius: 10px;
    border: none;
    color: var(--text-color);
    font-family: var(--base-font-family);
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

#label-choice-organization{
    font-size: 25px;
    color: var(--base-text-color);
    z-index: 5;
    background: none;
    text-align: center;
    font-family: var(--base-font-family);
}

#div-for-choice-organization::-webkit-scrollbar {
    max-width: 5px;
}

#div-for-choice-organization::-webkit-scrollbar-track {
    background: none;
}

#div-for-choice-organization::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 5px;
}