.main-profile{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    height: 100vh;
    background-color: transparent;
    gap: 30px;
}

#yandexAuthButtonContainerProfile{
    margin-top: 10px;
    min-width: 190px;
    border-radius: 10px;
}

@media (width < 1650px) {
    .main-profile {
        display: grid;
        grid-template-columns: min-content min-content;
        grid-auto-rows: min-content;
        overflow: scroll;
        align-items: center;
        justify-content: center;
    }
    .left-column-profile {
        display: flex;
        grid-column: 1;
        grid-row: 1;
        margin-left: auto;
        margin-top: 20vh;
    }
    .center-column-profile {
        grid-column: 2;
        grid-row: 1;
        margin-top: 20vh;
        margin-right: auto;
        padding-right: 20px;
    }
    .right-column-profile {
        grid-row: 2;
        justify-content: center;
        grid-column: 1 / span 2;
    }
}

@media (width < 1060px) {
    .main-profile {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: min-content;
        align-items: center;
        justify-content: center;
        max-height: 98vh;
    }
    .main-profile > * {
        justify-self: center;
        align-self: center;
    }
    .left-column-profile {
        display: flex;
        grid-column: 1;
        grid-row: 1;
        margin-left: 0;
        margin-top: 12vh;
    }
    .center-column-profile {
        grid-column: 1;
        margin-top: 0;
        grid-row: 2;
        margin-right: 0;
    }
    .right-column-profile {
        grid-column: 1;
        grid-row: 3;
    }
}

@media (width < 750px) {
    .main-profile {
        max-height: 90vh;
    }
    .center-column-first-row{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: fit-content !important;
        width: 200px;
        align-self: center;
    }
    .center-column-second-row{
        width: 200px;
    }
    .right-column-profile{
        flex-direction: column;
    }
}

.left-column-profile{
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 2;
}

.center-column-first-row{
    display: flex;
    height: 200px;
    background: var(--base-modal-color);
    backdrop-filter: blur(10px);
    padding: 20px 40px 10px 40px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.center-column-second-row{
    display: flex;
    flex-direction: column;
    max-width: 515px;
    height: 400px;
    overflow: hidden;
    background: var(--base-modal-color);
    backdrop-filter: blur(10px);
    padding: 20px 40px 10px 40px;
    border-radius: 10px;
}

.center-column-profile{
    display: flex;
    flex-direction: column;
    z-index: 2;
}

.div-notification-checkbox{
    display: flex;
    align-items: center;
    gap: 5px;
    padding-bottom: 5px;
    border-bottom: 1px Solid rgb(128, 128, 128);
}

#title-profile-task{
    font-family: var(--base-font-family);
    font-size: 18px;
    font-weight: bold;
    color: var(--base-text-color);
}

.right-column-profile{
    display: flex;
    z-index: 2;
    background: var(--base-modal-color);
    backdrop-filter: blur(10px);
    padding: 20px 20px 20px 20px;
    border-radius: 10px;
    gap: 20px;
}

#title-profile-statistics{
    font-family: var(--base-font-family);
    font-size: 18px;
    font-weight: bold;
    color: var(--base-text-color);
}

#label-count-statistics{
    font-family: var(--base-font-family);
    font-size: 14px;
    font-weight: 400;
    color: var(--base-text-color);
}

#label-count-data-statistics{
    font-family: var(--base-font-family);
    font-size: 26px;
    font-weight: bold;
    color: var(--base-text-color);
}

#div-profile-statistics{
    display: flex;
    flex-direction: column;
    justify-content: left;
    background: var(--base-modal-color);
    border-radius: 10px;
    margin-top: auto;
    padding: 5px;
    max-width: 95px;
    margin-right: 10px;
    margin-bottom: 20px;
    font-family: var(--base-font-family);
    gap: 20px;
}

#main-profile-div{
    display: flex;
    justify-content: center;
    min-width: 100%;
    align-items: center;
    background: none;
}

#photo-user-update-div{
    display: flex;
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 10px;
    height: fit-content;
    padding-bottom: 15px;
    flex-direction: column;
    border-radius: 10px 0px 0px 10px;
    z-index: 1;
    align-items: center;
    justify-content: center;
}

#photoprofile{
    width: 225px;
    height: 225px;
    border-radius: 1000px;
    border: 4px solid var(--base-bg-color);
}

#save-changes-cropp-photo{
    display: flex;
    width: 200px;
    height: 40px;
    font-family: var(--base-font-family);
    font-size: 16px;
    color: var(--base-text-color);
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 5px;
    transition: box-shadow 0.3s ease, transform 0.1s ease;
    border-radius: 10px;
    border: none;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

#div-tg-btn-connect{
    display: flex;
    background-color: var(--telegram-btn-color);
    border-radius: 10px;
    border: none;
    color: var(--base-text-color);
    width: 245px;
    height: 40px;
    justify-content: center;
    align-items: center;
}

.input-file{
    display: flex;
    width: 200px;
    height: 40px;
    margin-top: 10px;
    cursor: pointer;
    background-color: var(--base-btn-color);
    transition: box-shadow 0.3s ease, transform 0.1s ease, background-color 0.3s ease;
    border-radius: 10px;
    border: none;
    align-items: center;
    justify-content: center;
}

.input-file:hover{
    box-shadow: 0px 4px 14px 1px var(--base-btn-hover-color);
    background-color: var(--base-btn-hover-color);
}

#input-file-text{
    font-size: 16px;
    color: var(--text-color);
    font-family: var(--base-font-family);
    font-weight: 700;
}

#cropped-image{
    position: relative;
    display: flex;
    z-index: 20;
    width: 300px;
    height: 300px;
    border-radius: 20px;
}

#add-photo-profile{
    display: none;
}

#btn-unconnect-tg{
    display: flex;
    width: 190px;
    height: 44px;
    margin-bottom: 10px;
    background: var(--telegram-unconnect-btn-color);
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    font-size: 16px;
    font-family: var(--base-font-family);
    color: var(--text-color);
    border: none;
    font-weight: 600;
    cursor: pointer;
    transition: box-shadow 0.2s ease, transform 0.1s ease;
}

#btn-unconnect-tg:hover{
    box-shadow: var(--telegram-unconnect-btn-color) 0.5px 2px 20px;
    background-color: var(--telegram-unconnect-btn-color);
}

#go-to-unauth-btn{
    display: flex;
    width: 190px;
    height: 40px;
    background: var(--base-btn-color);
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    font-size: 16px;
    font-family: var(--base-font-family);
    color: var(--base-text-color);
    border: none;
    font-weight: 600;
    cursor: pointer;
    transition: box-shadow 0.2s ease, transform 0.1s ease;
}

#mainusername{
    display: flex;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 24px;
    font-weight: 800;
    text-wrap: wrap;
    word-wrap: break-word;
    max-width: 200px;
    align-items: center;
    justify-content: center;
}

#post-select-change{
    width: 245px;
    margin-bottom: 10px;
    padding-left: 10px;
    font-family: var(--base-font-family);
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    border: none;
    border-radius: 30px;
    background-color: var(--base-select-filter-color);
    cursor: pointer;
    color: var(--base-text-color);
}

#divprofileform{
    display: flex;
    margin-top: 200px;
    margin-bottom: auto;
    background: var(--base-element-color);
    width: fit-content;
    height: fit-content;
    justify-content: center;
    border-radius: 18px;
    z-index: 1;
}

#save-profile{
    height: 40px;
    width: 200px;
    color: var(--base-text-color);
    background: var(--create-btn-color);
    border-radius: 10px;
    border: none;
    margin-top: 5px;
    transition: box-shadow 0.3s ease, transform 0.1s ease, background-color 0.3s ease;
    font-family: var(--base-font-family);
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    z-index: 1;
}

#save-profile:hover{
    box-shadow: 0px 4px 14px 1px var(--create-btn-color);
    background-color: var(--create-btn-color);
}

#edit-profile{
    display: flex;
    width: 200px;
    height: 40px;
    cursor: pointer;
    background-color: var(--base-btn-color);
    transition: box-shadow 0.3s ease, transform 0.1s ease, background-color 0.3s ease;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    font-family: var(--base-font-family);
    color: var(--text-color);
    font-weight: 700;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
}

#edit-profile:hover{
    box-shadow: 0px 4px 14px 1px var(--base-btn-hover-color);
    background-color: var(--base-btn-hover-color);
}

#profileform{
    display: flex;
}

#div-column{
    display: flex;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 10px;
    align-items: center;
    max-width: 250px;
    flex-direction: column;
    text-align: left;
}

#div-column-user-info-inputs{
    display: flex;
    width: 250px;
    height: fit-content;
    align-items: center;
    margin-bottom: auto;
    flex-direction: column;
    text-align: left;
    margin-top: 15px;
}

#div-column-user-info{
    display: flex;
    margin-top: 200px;
    margin-bottom: auto;
    max-width: 250px;
    max-height: 480px;
    align-items: center;
    flex-direction: column;
    text-align: left;
    gap: 10px;
    z-index: 2;
}

#profile-template{
    border-radius: 10px;
    padding: 0px 15px 0px 15px;
    box-sizing: border-box;
    margin-bottom: 10px;
    border: var(--border-input);
    width: 250px;
    font-size: 15px;
    height: 40px;
    outline: none;
    background: var(--bg-color-input);
    color: var(--base-text-color);
}

#userorganization{
    width: 230px;
    padding: 10px;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    text-align: left;
    justify-content: center;
    background: none;
    border: var(--base-border-input);
    border-radius: 10px;
    margin-bottom: 15px;
    outline: none;
}

#userpost{
    width: 230px;
    padding: 10px;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    text-align: left;
    justify-content: center;
    background: none;
    border: var(--base-border-input);
    border-radius: 10px;
    margin-bottom: 10px;
}

#changeuserpassword{
    width: 200px;
    height: 40px;
    border: none;
    border-radius: 10px;
    background: var(--base-btn-color);
    color: var(--text-color);
    transition: box-shadow 0.3s ease, transform 0.1s ease, background-color 0.3s ease;
    font-family: var(--base-font-family);
    font-size: 16px;
    margin-top: 10px;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
}

#changeuserpassword:hover{
    box-shadow: 0px 4px 14px 1px var(--base-btn-hover-color);
    background-color: var(--base-btn-hover-color);
}

#hintforpassword{
    width: 200px;
    font-size: 14px;
    text-wrap: wrap;
    margin-top: 6px;
    font-family: var(--base-font-family);
    text-align: center;
    color: var(--base-hint-text-color);
}

#notification{
    display: flex;
    font-size: 14px;
    font-family: var(--base-font-family);
    color: var(--base-text-color);
    width: 200px;
    text-align: center;
}

#status-notification-mail{
    display: flex;
    min-width: 25px;
    min-height: 25px;
    cursor: pointer;
}