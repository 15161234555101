#registration-confirm{
    height: 40px;
    padding: 15px;
    border: 0px;
    border-radius: 20px;
    background: var(--base-btn-color);
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    font-size: 16px;
    font-weight: 700;
}

#registration-confirm:hover{
    background: var(--base-btn-hover-color);
    color: white;
    box-shadow: var(--base-btn-hover-color) 0.5px 2px 20px;
}

.registration-form h1{
    color: var(--base-text-color);
    margin-bottom: 30px;
    font-family: var(--base-font-family);
    margin-top: 0px;
}

#registration-username-txt{
    width: 100%;
    padding: 15px;
    font-size: 16px;
    color: var(--base-text-color);
    font-family: var(--base-font-family);
    box-sizing: border-box;
    border-radius: 30px;
    border: var(--base-border-input);
    outline: none;
    background: var(--base-bg-color-input);
}

#hint-for-user{
    display: flex;
    font-family: var(--base-font-family);
    max-width: 100%;
    color: var(--base-hint-text-color);
    font-size: 14px;
    margin-top: 3px;
    margin-bottom: 10px;
    text-align: left;
}

#registration-template-txt{
    width: 100%;
    padding: 15px;
    font-size: 16px;
    color: var(--base-text-color);
    margin-bottom: 10px;
    font-family: var(--base-font-family);
    box-sizing: border-box;
    border-radius: 30px;
    border: var(--base-border-input);
    outline: none;
    background: var(--base-bg-color-input);
}

.registration-form{
    display: flex;
    border: 20px;
    box-shadow: 0px 10px 25px 0px rgba(39, 33, 44, 0.733);
    border-radius: 20px;
    flex-direction: column;
    width: 400px;
    height: fit-content;
    padding: 40px;
    box-sizing: border-box;
    background-color: var(--base-element-color);
    z-index: 1;
    justify-content: center;
    align-items: center;
    align-content: center;
}