.theme-light {
    --base-text-color: #333333;
    --text-color: #ffffff;
    --base-font-family: 'Roboto', sans-serif;

    --base-bg-color: #fdfdfd;
    --base-element-color: #dfe6e9;
    --hello-background-color: rgb(224, 230, 235);

    --base-task-color: #ffffff;
    --base-task-hover-color: #f7f7f7;
    --task-list-color: rgb(223, 230, 233);
    --task-right-element-color: #fbfbfb;
    --task-right-element-shadow: inset 0px 0px 15px 5px rgba(200, 200, 200, 0.25);
    --task-data-element-hover-color: #f1f1f1;
    --task-modal-element-color: rgba(184, 200, 201, 0.6);
    --base-modal-color:  rgba(184, 216, 241, 0.6);
    --base-overlay-color: rgba(250, 250, 250, 0.8);
    --overlay-info-color: rgba(240, 240, 240, 0.9);
    --btn-elenemt-checkbox-color: rgb(144, 164, 174);
    --btn-elenemt-checkbox-active-color: rgb(8, 127, 140);

    --card-board-color: #dfe6e9;
    --card-board-hover-color: #c0c7ca;

    --base-btn-color: rgb(144, 164, 174);
    --base-btn-hover-color: #90a4ae;
    --create-btn-color: #28a745;
    --delete-btn-color: #df5260;
    --hide-board-btn-color: #ffc107;
    --telegram-btn-color: #54a9eb;
    --telegram-unconnect-btn-color: #ee606e;
    --btn-invite-user-organization-color: #6c757d;
    --btn-data-element-color: #ced4da;
    --btn-data-element-hover: #adb5bd;

    --base-select-filter-color: rgb(223, 230, 233, 0.4);

    --base-sidebar-color: rgb(223, 230, 233, 0.6);
    --sidebar-hover-color: rgb(223, 230, 233, 0.6);
    --post-sidebar-color: #578ec5;
    --base-note-color: #fffdfd;
    --base-header-color: #f1f3f5;
    --hint-note-color: #2e2e2e;
    --note-date-time-color: #6c757d;
    --note-link-color: #007bff;

    --line-main-color: #747474;

    --priority-low-color: #28a745;
    --priority-medium-color: #fd7e14;
    --priority-hight-color: #dc3545;
    --priority-select-color: #ced4da;
    --priority-select-selected-color: #007bff;
    --big-priority-color: rgb(144, 164, 174);
    --big-priority-selected-color: #087F8C;
    --big-priority-text-color: #333333;
    --big-priority-text-selected-color: white;

    --mini-task-card-color: #f8f9fa;
    --title-data-task-color: #343a40;
    --base-border-input: 1px solid #494b4e;
    --border-input: none;
    --base-hint-text-color: #6c757d;
    --base-bg-color-input: none;
    --bg-color-input: #dfe6e9;
    --base-placeholder-input: #6c757d;

    --hint-no-permissions-or-null-data: #fff3e0;
    --time-task-status-color: #fd7e14;

    --base-table-color: #ffffff;
    --base-table-hover-color: #f8f9fa;
    --base-table-btn-color: #ced4da;
    --base-table-btn-hover-color: #adb5bd;

    --base-tooltip-color: #74b9ff;

    --file-place-border: 2px dashed #ced4da;
    --add-files-icon-color: #6c757d;
    --file-place-border-hover: 2px dashed #007bff;
    --file-place-bg-hover-color: #e3f2fd;

    --history-hint-color: #6c757d;
    --comment-left-color: #f8f9fa;
    --comment-right-color: #e9ecef;

    --base-checkbox-bg-color: #90a4ae;
    --base-checkbox-bg-input-color:  #eee;
    --base-checkbox-checked-bg-color:  rgb(3, 178, 197);
    
    --base-checkbox-side-indicator: #9cc5f5;
    --base-checkbox-side-active-indicator: rgb(58, 118, 247);

    --ck-custom-foreground: hsl(240, 32%, 90%);
    --ck-custom-color-button-default-hover-background: hsl(240, 44%, 87%);
    --ck-custom-color-button-default-active-background: hsl(269, 32%, 86%);
    --ck-custom-color-button-default-active-shadow: hsl(279, 40%, 84%);
    --ck-custom-color-button-on-hover-background: hsl(240, 54%, 85%);
    --ck-custom-color-button-on-active-background: hsl(258, 28%, 82%);
    --ck-custom-color-button-on-active-shadow: hsl(277, 44%, 81%);
    --ck-custom-color-dialog-background: rgba(169, 174, 211, 0.8);
    --ck-custom-accessibility-help-dialog-code-background-color: rgb(217, 219, 235);

    --switch-container-color: none;
    --switch-slider-color: rgb(250, 239, 179);

    --gantt-taxt-color: rgb(235, 235, 235);
    --dhx-custom-gantt-base-colors-white: #ffffff !important; 
    --dhx-custom-gantt-base-colors-select: #e6e7fd !important; /* Темный оттенок */
    --dhx-custom-gantt-base-colors-hover-color: #cbcefc !important; /* Темный оттенок при наведении */
    --dhx-custom-gantt-base-colors-border-light: rgb(63, 63, 63) !important; /* Темная граница */
    --dhx-custom-gantt-base-colors-border: rgb(63, 63, 63) !important; /* Еще темнее граница */

    --dhx-custom-gantt-base-colors-icons: #212222 !important; /* Иконки */
    --dhx-custom-gantt-base-colors-disabled: #4a4b5e !important; /* Отключенные элементы */
    --dhx-custom-gantt-base-colors-text-light: #020202 !important; /* Светлый текст */
    --dhx-custom-gantt-base-colors-text-base: #252525 !important; /* Основной текст (белый) */
    --dhx-custom-gantt-base-colors-text-on-fill: rgba(255, 255, 255, 0.9) !important; /* Текст на заливке */
    --dhx-custom-gantt-base-colors-background: rgb(255, 255, 255) !important; /* Темный фон */
    --dhx-custom-gantt-base-colors-background-alt: rgb(196, 199, 233) !important; /* Альтернативный темный фон */


    --dhx-custom-scheduler-base-colors-primary: #537CFA;
    --dhx-custom-scheduler-base-colors-warning: #FAB936;
    --dhx-custom-scheduler-base-colors-error: #E3334E;
    --dhx-custom-scheduler-base-colors-error-text: #000000;
    --dhx-custom-scheduler-base-colors-success: #1BC297;
    --dhx-custom-scheduler-base-colors-secondary: rgba(241, 241, 241, 0.04);
    --dhx-custom-scheduler-btn-outline-color: white !important;
    --dhx-custom-scheduler-btn-outline-border-color: rgb(186, 190, 233) !important;
    --dhx-custom-scheduler-btn-outline-background: rgb(195, 197, 228) !important;
    --dhx-custom-scheduler-btn-outline-border-active: rgb(181, 184, 223) !important;
    --dhx-custom-scheduler-btn-outline-color-hover: white !important;
    --dhx-custom-scheduler-btn-outline-border-hover: rgb(44, 45, 55) !important;
    --dhx-custom-scheduler-btn-background: rgb(195, 198, 236) !important;
    --dhx-custom-scheduler-btn-border-color: rgb(185, 188, 224) !important;
    --dhx-custom-scheduler-base-colors-select: #afb2db !important;
    --dhx-custom-scheduler-base-colors-border: rgb(44, 45, 55) !important;
    --dhx-custom-scheduler-base-colors-icons: #888d91 !important;
    --dhx-custom-scheduler-base-colors-disabled: #1b1a1a;
    --dhx-custom-scheduler-base-colors-readonly: #1f2020;
    --dhx-custom-scheduler-base-colors-text-light: #323436 !important;
    --dhx-custom-scheduler-base-colors-text-base: #141414 !important;
    --dhx-custom-scheduler-base-colors-background: rgb(236, 237, 245) !important;

/* design calendar *//* design calendar *//* design calendar *//* design calendar *//* design calendar *//* design calendar */

.react-calendar {
    width: 350px;
    max-width: 100%;
    border: 0;
    color: black;
    border-radius: 20px;
    font-weight: 600;
    font-family: var(--base-font-family);
    line-height: 1.125em;
    background-color: transparent;
    border-radius: 10px;
}
  
  .react-calendar--doubleView {
    width: 700px;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  
  .react-calendar__navigation {
    display: flex;
    height: 30px;
    color: rgb(46, 46, 46);
    margin-bottom: 5px;
  }
  
  .react-calendar__navigation button {
    min-width: 30px;
    color: rgb(46, 46, 46);
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    background: none;
  }
  
  .react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
  }
  
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #859afa;
    border-radius: 15px;
    color: white;
  }
  
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    color: rgb(46, 46, 46);
    font-size: 0.75em;
    font-weight: bold;
  }
  
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(46, 46, 46);
    font-family: 'Roboto', sans-serif;
    font-size: 0.75em;
    font-weight: bold;
  }
  
  .react-calendar__month-view__days__day--weekend {
    color: #f75c5c;
  }
  
  .react-calendar__month-view__days__day--neighboringMonth,
  .react-calendar__decade-view__years__year--neighboringDecade,
  .react-calendar__century-view__decades__decade--neighboringCentury {
    color: rgb(46, 46, 46);
  }
  
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
    color: rgb(46, 46, 46);
  }
  
  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    color: rgb(46, 46, 46);
    line-height: 16px;
    font-family: 'Roboto', sans-serif;
    font-size: 0.9em;
  }
  
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
    color: rgb(46, 46, 46);
  }
  
  .react-calendar__month-view__days__day--neighboringMonth:disabled,
  .react-calendar__decade-view__years__year--neighboringDecade:disabled,
  .react-calendar__century-view__decades__decade--neighboringCentury:disabled {
    color: rgb(46, 46, 46);
  }
  
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #859afa;
    border-radius: 30px;
  }
  
  .react-calendar__tile--now {
    background: #fadf85;
    border-radius: 15px;
  }
  
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #8957B5;
  }
  
  .react-calendar__tile--hasActive {
    background: #859afa;
    border-radius: 30px;
  }
  
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #6C57B5;
  }
  
  .react-calendar__tile--active {
    background: #859afa;
    border-radius: 15px;
    color: white;
  }
  
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #6564af;
    border-radius: 15px;
  }
  
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  
  /* design calendar *//* design calendar *//* design calendar *//* design calendar *//* design calendar *//* design calendar */
    
}
  
.theme-dark {
    --base-text-color: #ffffff;
    --text-color: #ffffff;
    --base-font-family: 'Roboto', sans-serif;

    --base-bg-color: hsl(234, 10%, 20%);
    --base-element-color: rgb(57, 58, 69);
    --hello-background-color: rgb(50, 51, 61);

    --base-task-color: #474858;
    --base-task-hover-color: #4E4F61;
    --task-list-color: #393A45;
    --task-right-element-color: rgba(34, 35, 43);
    --task-right-element-shadow: inset 0px 0px 15px 5px rgba(34, 35, 43, 0.6);
    --task-data-element-hover-color: #393a45;
    --task-modal-element-color: rgba(34, 35, 43, 0.6);
    --base-modal-color: rgba(34, 35, 43, 0.6);
    --base-overlay-color: transparent;
    --overlay-info-color: rgba(50, 51, 61, 0.616);

    --card-board-color: #282933;
    --card-board-hover-color: #684689;

    --base-btn-color: rgb(34, 35, 39);
    --base-btn-hover-color: #684689;
    --create-btn-color: rgba(22, 121, 179, 0.808);
    --delete-btn-color: #b83e3e;
    --hide-board-btn-color: rgba(22, 179, 74, 0.66);
    --telegram-btn-color: #4D92CB;
    --telegram-unconnect-btn-color: #B83E3E;
    --btn-invite-user-organization-color: rgb(74, 80, 143);
    --btn-data-element-color: #4e506d;
    --btn-data-element-hover: hsl(208, 90%, 62%);
    --btn-elenemt-checkbox-color: rgb(91, 93, 108);
    --btn-elenemt-checkbox-active-color: rgb(87, 207, 255, 0.3);

    --base-select-filter-color: rgb(34, 35, 39, 0.6);

    --base-sidebar-color: rgba(16, 17, 20, 0.6);
    --sidebar-hover-color: rgba(34, 35, 43, 0.6);
    --post-sidebar-color: rgba(255, 255, 255, 0.6);
    --base-note-color: #474858;
    --base-header-color: #32333D;
    --hint-note-color: antiquewhite;
    --note-date-time-color: rgb(199, 151, 90);
    --note-link-color: rgb(95, 139, 206);

    --line-main-color: #595A69;

    --priority-low-color: rgb(63, 127, 232);
    --priority-medium-color: rgb(220, 136, 0);
    --priority-hight-color: rgb(175, 52, 52);
    --priority-select-color: hsl(235, 17%, 41%, 0.9);
    --priority-select-selected-color: #087F8C;
    --big-priority-color: rgb(44, 45, 55);
    --big-priority-selected-color: #087F8C;
    --big-priority-text-color: rgb(150, 134, 134);
    --big-priority-text-selected-color: white;

    --mini-task-card-color: #5d5f79;
    --title-data-task-color: #7F8094;
    --base-border-input: 1px solid rgba(99, 94, 94, 0.623);
    --border-input: none;
    --base-hint-text-color: rgb(200, 200, 200);
    --base-bg-color-input: none;
    --bg-color-input: rgb(34, 35, 39);
    --base-placeholder-input: rgb(112, 112, 121);

    --hint-no-permissions-or-null-data: rgba(102, 75, 185, 0.507);
    --time-task-status-color: rgb(218, 151, 51);

    --base-table-color: #474a75;
    --base-table-hover-color: #606097;
    --base-table-btn-color: #757baa;
    --base-table-btn-hover-color: #2973E3;

    --base-tooltip-color: #7B5FFF;

    --file-place-border: 2px dashed rgba(34, 35, 43, 0);
    --add-files-icon-color: rgba(34, 35, 43, 0.6);
    --file-place-border-hover: 2px dashed #007bff;
    --file-place-bg-hover-color: rgba(0, 123, 255, 0.05);

    --history-hint-color: rgb(148, 155, 167); 
    --comment-left-color: rgba(34, 35, 43, 0.6);
    --comment-right-color: #5B5D6C;

    --base-checkbox-bg-color: #eee;
    --base-checkbox-bg-input-color:  #fff;
    --base-checkbox-checked-bg-color:  #f7cb15;

    --base-checkbox-side-indicator: #7F8094;
    --base-checkbox-side-active-indicator: white;

    --ck-custom-foreground: hsl(255, 3%, 18%);
    --ck-custom-color-button-default-hover-background: hsl(270, 1%, 22%);
    --ck-custom-color-button-default-active-background: hsl(270, 2%, 20%);
    --ck-custom-color-button-default-active-shadow: hsl(270, 2%, 23%);
    --ck-color-button-on-hover-background: hsl(255, 4%, 16%);
    --ck-color-button-on-active-background: hsl(255, 4%, 14%);
    --ck-color-button-on-active-shadow: hsl(240, 3%, 19%);
    --ck-custom-color-dialog-background: rgba(34, 35, 43, 0.8);
    --ck-custom-accessibility-help-dialog-code-background-color: rgba(34, 35, 43, 1);

    --switch-container-color: none;
    --switch-slider-color: rgb(78, 94, 240);

    --gantt-taxt-color: rgb(235, 235, 235);
    --dhx-custom-gantt-base-colors-white: #FFFFFF !important; 
    --dhx-custom-gantt-base-colors-select: #3a3b4e !important; /* Темный оттенок */
    --dhx-custom-gantt-base-colors-hover-color: #4a4b5e !important; /* Темный оттенок при наведении */
    --dhx-custom-gantt-base-colors-border-light: rgb(44, 45, 55) !important; /* Темная граница */
    --dhx-custom-gantt-base-colors-border: rgb(44, 45, 55) !important; /* Еще темнее граница */

    --dhx-custom-gantt-base-colors-icons: #A1A4A6 !important; /* Иконки */
    --dhx-custom-gantt-base-colors-disabled: #4a4b5e !important; /* Отключенные элементы */
    --dhx-custom-gantt-base-colors-text-light: #B0B3B8 !important; /* Светлый текст */
    --dhx-custom-gantt-base-colors-text-base: #FFFFFF !important; /* Основной текст (белый) */
    --dhx-custom-gantt-base-colors-text-on-fill: rgba(255, 255, 255, 0.90) !important; /* Текст на заливке */
    --dhx-custom-gantt-base-colors-background: rgb(57, 58, 69) !important; /* Темный фон */
    --dhx-custom-gantt-base-colors-background-alt: rgb(44, 45, 55) !important; /* Альтернативный темный фон */


    --dhx-custom-scheduler-base-colors-primary: #537CFA;
    --dhx-custom-scheduler-base-colors-warning: #FAB936;
    --dhx-custom-scheduler-base-colors-error: #E3334E;
    --dhx-custom-scheduler-base-colors-error-text: #FFFFFF;
    --dhx-custom-scheduler-base-colors-success: #1BC297;
    --dhx-custom-scheduler-base-colors-secondary: rgba(0, 0, 0, 0.04);
    --dhx-custom-scheduler-btn-outline-color: white !important;
    --dhx-custom-scheduler-btn-outline-border-color: rgb(44, 45, 55) !important;
    --dhx-custom-scheduler-btn-outline-background: rgb(57, 58, 69) !important;
    --dhx-custom-scheduler-btn-outline-border-active: rgb(44, 45, 55) !important;
    --dhx-custom-scheduler-btn-outline-color-hover: white !important;
    --dhx-custom-scheduler-btn-outline-border-hover: rgb(44, 45, 55) !important;
    --dhx-custom-scheduler-btn-background: rgb(57, 58, 69) !important;
    --dhx-custom-scheduler-btn-border-color: rgb(44, 45, 55) !important;
    --dhx-custom-scheduler-base-colors-select: #5d5f79 !important;
    --dhx-custom-scheduler-base-colors-border: rgb(44, 45, 55) !important;
    --dhx-custom-scheduler-base-colors-icons: #A1A4A6 !important;
    --dhx-custom-scheduler-base-colors-disabled: #E9E9E9;
    --dhx-custom-scheduler-base-colors-readonly: var(--dhx-scheduler-base-colors-icons);
    --dhx-custom-scheduler-base-colors-text-light: #B0B3B8 !important;
    --dhx-custom-scheduler-base-colors-text-base: #FFFFFF !important;
    --dhx-custom-scheduler-base-colors-background: rgb(57, 58, 69) !important;

/* design calendar *//* design calendar *//* design calendar *//* design calendar *//* design calendar *//* design calendar */

.react-calendar {
    width: 350px;
    max-width: 100%;
    border: 0;
    color: white;
    border-radius: 20px;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    line-height: 1.125em;
    background-color: transparent;
    border-radius: 10px;
}
  
  .react-calendar--doubleView {
    width: 700px;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  
  .react-calendar__navigation {
    display: flex;
    height: 30px;
    color: white;
    margin-bottom: 5px;
  }
  
  .react-calendar__navigation button {
    min-width: 30px;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    background: none;
  }
  
  .react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
  }
  
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #31323A;
    border-radius: 15px;
  }
  
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 0.75em;
    font-weight: bold;
  }
  
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-size: 0.75em;
    font-weight: bold;
  }
  
  .react-calendar__month-view__days__day--weekend {
    color: #f75c5c;
  }
  
  .react-calendar__month-view__days__day--neighboringMonth,
  .react-calendar__decade-view__years__year--neighboringDecade,
  .react-calendar__century-view__decades__decade--neighboringCentury {
    color: #cfcece;
  }
  
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
    color: white;
  }
  
  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    color: white;
    line-height: 16px;
    font-family: 'Roboto', sans-serif;
    font-size: 0.9em;
  }
  
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
    color: #ababab;
  }
  
  .react-calendar__month-view__days__day--neighboringMonth:disabled,
  .react-calendar__decade-view__years__year--neighboringDecade:disabled,
  .react-calendar__century-view__decades__decade--neighboringCentury:disabled {
    color: #cdcdcd;
  }
  
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #31323A;
    border-radius: 30px;
  }
  
  .react-calendar__tile--now {
    background: #6C57B5;
    border-radius: 15px;
  }
  
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #8957B5;
  }
  
  .react-calendar__tile--hasActive {
    background: #6765ca;
    border-radius: 30px
  }
  
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #6C57B5;
  }
  
  .react-calendar__tile--active {
    background: #706fb6;
    color: white;
    border-radius: 15px;
  }
  
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #6564af;
    border-radius: 15px;
  }
  
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  
  /* design calendar *//* design calendar *//* design calendar *//* design calendar *//* design calendar *//* design calendar */

}

.theme-switcher {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 20px;
}

.switch-container {
  display: flex;
  position: relative;
  background: var(--switch-container-color);
  border-radius: 30px;
  padding: 5px;
  justify-content: space-between;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.switch-slider {
  position: absolute;
  width: 35px;
  height: 33px;
  background: var(--switch-slider-color);
  border-radius: 30px;
  transition: left 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.switch-btn {
  flex: 1;
  width: 35px;
  height: 35px;
  background: none;
  border: none;
  padding: 5px;
  font-size: 20px;
  cursor: pointer;
  z-index: 2;
  transition: color 0.3s;
}

 /* выбранный элемент */
.switch-btn.active {
  color: #007bff;
}


.checkbox.style-e {
    display: inline-block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 22px;
}
.checkbox.style-e input:checked ~ .checkbox__checkmark {
    background-color: var(--base-checkbox-checked-bg-color);
}
.checkbox.style-e input:checked ~ .checkbox__checkmark:after {
    left: 21px;
}
.checkbox.style-e .checkbox__checkmark {
    height: 22px;
    width: 40px;
    background-color: var(--base-checkbox-bg-color);
    transition: background-color 0.25s ease;
    border-radius: 11px;
}
.checkbox.style-e .checkbox__checkmark:after {
    content: "";
    position: absolute;
    left: 3px;
    top: 3px;
    width: 16px;
    height: 16px;
    display: block;
    background-color: var(--base-checkbox-bg-input-color);
    border-radius: 50%;
    transition: left 0.25s ease;
}